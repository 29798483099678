import React from 'react';
import {
    Dimensions,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../../constants/textStyles';
import { getTopInset } from '../../../../../utils/shared';
import XIcon from '../../../../../resources/svgComponents/XIcon';
import Profile2Icon from '../../../../../resources/svgComponents/Profile2Icon';

const headerHeight = 59 + getTopInset();

const ChatroomHeader = ({
    title,
    onPress,
    userCount,
}: {
    title: string;
    userCount: number;
    onPress: () => void;
}) => {
    return (
        <View style={styles.container}>
            <View style={[styles.gradient, styles.hasScroll]} />
            <View style={styles.headerContent}>
                <Text style={styles.titleText}>{title}</Text>
                <View style={styles.rightWrapper}>
                    <TouchableOpacity style={styles.countWrapper} disabled>
                        <Profile2Icon />

                        <Text style={styles.countText}>{userCount}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.backIcon} onPress={onPress}>
                        <XIcon color="white" size={25} />
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};

export { ChatroomHeader };

const styles = StyleSheet.create({
    container: {
        height: headerHeight,
        justifyContent: 'flex-end',
        backgroundColor: '#F35E2E',
        marginHorizontal: -16,
    },
    gradient: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: '#F35E2E',
    },
    hasScroll: {
        height: Dimensions.get('window').height,
        top: -Dimensions.get('window').height + headerHeight,
    },
    headerContent: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 30,
        paddingBottom: 15,
        justifyContent: 'space-between',
        width: '100%',
    },
    backIcon: {
        alignSelf: 'center',
    },
    titleText: mbTextStyles([
        textStyles.largeText,
        {
            fontFamily: FONT_STYLES.Tabasco_400,
            fontWeight: '400',
            fontSize: 22,
            textAlign: 'center',
        },
    ]),
    rightWrapper: {
        flexDirection: 'row',
    },
    countWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRadius: 20,
        backgroundColor: '#FFFFFF',
        marginRight: 12,
    },
    countText: {
        fontSize: 20,
        color: '#1C1D20F4',
        fontFamily: FONT_STYLES.Tabasco_400,
        marginTop: 2,
    },
});
