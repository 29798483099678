import { DateInput } from '../inputs';
import {
    ZodiacSign,
    AgeGroup,
    UserSelectTags,
    PointEvents,
    AnswerType,
    ResultType,
    ShapeCategories,
    ChatRoomTypes,
} from '../entities';

export function tellSign(dob: DateInput | undefined): ZodiacSign | undefined {
    if (!dob) return undefined;

    const westernZodiacSigns = {
        [ZodiacSign.aries]: {
            start: { month: 3, day: 21 },
            end: { month: 4, day: 19 },
        },
        [ZodiacSign.taurus]: {
            start: { month: 4, day: 20 },
            end: { month: 5, day: 20 },
        },
        [ZodiacSign.gemini]: {
            start: { month: 5, day: 21 },
            end: { month: 6, day: 20 },
        },
        [ZodiacSign.cancer]: {
            start: { month: 6, day: 21 },
            end: { month: 7, day: 22 },
        },
        [ZodiacSign.leo]: {
            start: { month: 7, day: 23 },
            end: { month: 8, day: 22 },
        },
        [ZodiacSign.virgo]: {
            start: { month: 8, day: 23 },
            end: { month: 9, day: 22 },
        },
        [ZodiacSign.libra]: {
            start: { month: 9, day: 23 },
            end: { month: 10, day: 22 },
        },
        [ZodiacSign.scorpio]: {
            start: { month: 10, day: 23 },
            end: { month: 11, day: 21 },
        },
        [ZodiacSign.sagittarius]: {
            start: { month: 11, day: 22 },
            end: { month: 12, day: 21 },
        },
        [ZodiacSign.capricorn]: {
            start: { month: 12, day: 22 },
            end: { month: 1, day: 19 },
        },
        [ZodiacSign.aquarius]: {
            start: { month: 1, day: 20 },
            end: { month: 2, day: 18 },
        },
        [ZodiacSign.pisces]: {
            start: { month: 2, day: 19 },
            end: { month: 3, day: 20 },
        },
    };

    const { day, month } = dob;
    const sign = Object.entries(westernZodiacSigns).find(
        ([_, { start, end }]) => {
            if (month === start.month && day >= start.day) return true;
            if (month === end.month && day <= end.day) return true;
            return false;
        },
    );

    return sign ? (sign[0] as ZodiacSign) : undefined;
}

export function getAgeGroup(dob: DateInput | undefined): AgeGroup | undefined {
    if (!dob) return undefined;

    let age = new Date().getFullYear() - dob.year;

    if (
        dob.month < new Date().getMonth() + 1 ||
        (dob.month === new Date().getMonth() + 1 &&
            dob.day < new Date().getDate())
    ) {
        age++;
    }

    if (age >= 50) return AgeGroup['50s+'];
    if (age >= 40) return AgeGroup['40s'];
    if (age >= 30) return AgeGroup['30s'];
    if (age >= 22) return AgeGroup['20s'];
    return AgeGroup.lateTeens;
}

export function ageGroupToDisplayLabel(ageGroup: AgeGroup): string {
    switch (ageGroup) {
        case AgeGroup.lateTeens:
            return 'late teens';
        case AgeGroup['20s']:
            return '20s';
        case AgeGroup['30s']:
            return '30s';
        case AgeGroup['40s']:
            return '40s';
        case AgeGroup['50s+']:
            return '50s+';
    }
}

export function getGroupedTags() {
    return [
        {
            label: 'Additional Identities',
            tags: [
                UserSelectTags.cis,
                UserSelectTags.trans,
                UserSelectTags.genderqueer,
                UserSelectTags.nonBinary,
                UserSelectTags.twoSpirit,
                UserSelectTags.intersex,
                UserSelectTags.polysexual,
                UserSelectTags.monogamous,
                UserSelectTags.nonMonogamous,
                UserSelectTags.polyamorous,
                UserSelectTags.demisexual,
                UserSelectTags.demiromantic,
                UserSelectTags.asexual,
                UserSelectTags.aromantic,
                UserSelectTags.pansexual,
            ],
        },
        {
            label: 'Self-presentation',
            tags: [
                UserSelectTags.masc,
                UserSelectTags.fem,
                UserSelectTags.lipstickLesbian,
                UserSelectTags.chapstickLesbian,
                UserSelectTags.stem,
                UserSelectTags.futch,
                UserSelectTags.butch,
                UserSelectTags.stud,
                UserSelectTags.hyperFem,
                UserSelectTags.androgynous,
            ],
        },
        {
            label: 'Bedroom labels',
            tags: [
                UserSelectTags.bottom,
                UserSelectTags.top,
                UserSelectTags.switch,
                UserSelectTags.dominant,
                UserSelectTags.submissive,
                UserSelectTags.stoneButch,
                UserSelectTags.pillowPrincess,
            ],
        },
        {
            label: 'Other Roles',
            tags: [
                UserSelectTags.wife,
                UserSelectTags.mother,
                UserSelectTags.parent,
                UserSelectTags.girlfriend,
                UserSelectTags.partner,
                UserSelectTags.student,
                UserSelectTags.single,
                UserSelectTags.widow,
                UserSelectTags.divorced,
            ],
        },
    ];
}

export const fixedPointsAmount = {
    [PointEvents.initialAccountSetup]: 200,
    [PointEvents.startOfQuarter]: 100,
    [PointEvents.usedReferralCode]: 100,
    [PointEvents.questionAnswered]: 50,
    [PointEvents.betResult]: undefined,
    [PointEvents.idealBonus]: 100,
    [PointEvents.identityGroupBonus]: 50,
    [PointEvents.ageGroupBonus]: 25,
};

export const answerMargins = {
    [AnswerType.numeric]: {
        [ResultType.inWiggle]: 0,
        [ResultType.inMargin]: 1,
    },
    [AnswerType.percentage]: {
        [ResultType.inWiggle]: 2,
        [ResultType.inMargin]: 9,
    },
};

export const shapeCategoryToLabel = (category: ShapeCategories) => {
    switch (category) {
        case ShapeCategories.relationshipAndSex:
            return 'Relationship & Sex';
        case ShapeCategories.lifestyle:
            return 'Lifestyle';
        case ShapeCategories.substanceUse:
            return 'Substance Use';
        case ShapeCategories.identityAndCommunity:
            return 'Identity & Community';
        case ShapeCategories.wellBeing:
            return 'Well-being';
        case ShapeCategories.beliefs:
            return 'Beliefs';
    }
};

export function chatRoomNameToLabel(chatRoom: ChatRoomTypes): string {
    switch (chatRoom) {
        case ChatRoomTypes.general:
            return 'General';
        case ChatRoomTypes.relationships:
            return 'Relationships';
        case ChatRoomTypes.health:
            return 'Health';
        case ChatRoomTypes.culture:
            return 'Culture';
    }
}
