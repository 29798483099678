import React from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import GradientContainer from '../../../helperComponents/gradients/GradientContainer';
import renderRandomStars from '../../../../utils/shared';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { ShadowView } from '../../../helperComponents/ShadowView';
import { GradientButton } from '../../../helperComponents/gradients/GradientButton';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import StarIcon from '../../../../resources/svgComponents/StarIcon';
import { AddQuarterAnswerResponse, QuarterDataToPlay } from '@sapphicsavvy/business';
import AntDesign from 'react-native-vector-icons/AntDesign';
import PointsCard from '../../../helperComponents/PointsCard';

const stars: [number | string, number | string, number][] = [
    ['27%', '4%', 19],
    ['6%', '36%', 35],
    ['9%', '62%', 19],
    ['13%', '92%', 19],
    ['23%', '55%', 57],
    ['50%', '80%', 19],
    ['94%', '4%', 35],
    ['90%', '14%', 19],
    ['76%', '36%', 19],
    ['85%', '62%', 19],
    ['90%', '86%', 35],
];

type CompleteProps = {
    quarterData: QuarterDataToPlay,
    onPress?: () => void,
    points: AddQuarterAnswerResponse,
}

export default function Complete({ quarterData, points, onPress }: CompleteProps) {

    const { basePointsInFlux, bonusPointsInFlux, extraBetsInFlux, pointsOnCompletion } =  points;

    return (
        <GradientContainer style={styles.container} angle={-90}>
            <View style={styles.topSection}>
                {renderRandomStars(stars)}
                <Text style={styles.setText}>SET {quarterData.setNumber}</Text>
                <Text style={styles.quarterText}>QUARTER {quarterData.quarterNumber}</Text>
                <View style={styles.complete}>
                    <AntDesign name="checkcircle" size={19} color="#FFFFFF" />
                    <Text style={styles.completeText}> Complete</Text>
                </View>
            </View>
            <View style={styles.bottomSection}>
                <PointsCard
                    title="Points at completion"
                    points={pointsOnCompletion}
                    backgroundColor="#F9EFFF"
                    textColor="#6A2C8C"
                />
                <PointsCard
                    title="Base points in flux"
                    points={basePointsInFlux}
                    backgroundColor="#FFF0EC"
                    textColor="#F3522B"
                />
                <PointsCard
                    title="Bonus points in flux"
                    points={bonusPointsInFlux}
                    backgroundColor="#FFF9F1"
                    textColor="#FCAF41F4"
                />
                <PointsCard
                    title="Extra bets in flux"
                    points={extraBetsInFlux}
                    backgroundColor="#EBF9FF"
                    textColor="#0295CD"
                />
                <Text style={styles.resultTopTitleText}>Results will be ready</Text>
                <ShadowView style={styles.result}>
                    <Text style={styles.resultTitleText}>SATURDAY NOON PST</Text>
                    <View style={{ position: 'absolute', top: 0, left: 20, justifyContent: 'center' }}>
                        <StarIcon size={57} color={mbApplyTransparency('#F88337', 0.08)} />
                    </View>
                    <View style={{ position: 'absolute', top: 0, right: 50 }}>
                        <StarIcon size={38} color={mbApplyTransparency('#F88337', 0.08)} />
                    </View>
                </ShadowView>
                <GradientButton
                    title="BACK TO HOME"
                    containerStyle={styles.btn}
                    onPress={onPress}
                />
            </View>
        </GradientContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    topSection: {
        height: '33%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    setText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '700',
    }]),
    quarterText: mbTextStyles([textStyles.normalText, {
        fontSize: 45,
        fontWeight: '400',
        fontFamily: FONT_STYLES.Tabasco_400,
    }]),
    complete: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    completeText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '700',
    }]),
    bottomSection: {
        height: '67%',
        borderTopLeftRadius: 36,
        borderTopRightRadius: 36,
        backgroundColor: '#EEEEF2',
        paddingTop: Platform.select({
            default: 25,
            ios: 40,
        }),
        paddingHorizontal: 30,
        ...mbShadow({
            offsetWidth: -5,
            offsetHeight: 0,
            color: '#000000',
            opacity: 0.15,
            radius: 10,
            elevation: 2,
        }),
    },
    resultTopTitleText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        color: '#292E30',
        marginBottom: 10,
    }]),
    result: {
        paddingHorizontal: 20,
        paddingVertical: 20,
        marginBottom: 15,
    },
    resultTitleText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        fontSize: 25,
        color: '#EF3A24',
    }]),
    btn: {
        width: 227,
        alignSelf: 'center',
    },
});
