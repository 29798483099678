import { NotificationTypes } from './enums';

const EmojiCodes = {
    trophy: 'U+1F3C6',
    moneybag: 'U+1F4B0',
    goat: 'U+1F410',
    rainbow: 'U+1F308',
    twoWomenHoldingHands: 'U+1F46D',
    bullseyeTarget: 'U+1F3AF',
    faceSobbing: 'U+1F622',
    alarmClock: 'U+23F0',
    warning: 'U+26A0',
};

export const USERNAME_PLACEHOLDER = '{username}';
export const getDefaultNotification = (
    notificationType: NotificationTypes,
): {
    title: string;
    body: string;
} => {
    switch (notificationType) {
        case NotificationTypes.NEW_QUARTER: {
            return {
                title: `We missed you last week ${USERNAME_PLACEHOLDER} ${EmojiCodes.faceSobbing}`,
                body: `Come back! A new quarter just opened & more cash is up for grabs!`,
            };
        }
        case NotificationTypes.RESULTS_READY: {
            return {
                title: `Results are in ${USERNAME_PLACEHOLDER}`,
                body: `Find out if you made the leaderboard or won cash this quarter ${EmojiCodes.trophy} ${EmojiCodes.moneybag}`,
            };
        }
        case NotificationTypes.PLAY_QUARTER_FIRST_REMINDER: {
            return {
                title: `Reminder: Quarter Closing Soon`,
                body: `Good luck with your guesses & bets this week ${EmojiCodes.rainbow} ${EmojiCodes.twoWomenHoldingHands} ${EmojiCodes.trophy}`,
            };
        }
        case NotificationTypes.PLAY_QUARTER_SECOND_REMINDER: {
            return {
                title: `Last call for this quarter ${USERNAME_PLACEHOLDER} ${EmojiCodes.alarmClock} ${EmojiCodes.warning}`,
                body: `Get your guesses in before it’s too late! You got this!`,
            };
        }
        default: {
            return {
                title: '',
                body: '',
            };
        }
    }
};
