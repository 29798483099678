import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function ClosedBookIcon() {
  return (
    <Svg
      width={56}
      height={57}
      viewBox="0 0 56 57"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.578.725h39.347V47.64c-.068 1.147-1.567 1.174-3.19 1.101H14.026c-3.678.333-2.368 5.181 0 4.926h38.203v-1.67h3.186v2.831c0 .483-.194.947-.539 1.288a1.848 1.848 0 01-1.3.533h-39.39c-5.516.078-7.332-.214-7.332-4.21v-5.792h-4.21c-1.178 0-2.097-1.06-2.097-2.363 0-1.302.92-2.363 2.096-2.363h4.211v-3.77h-4.21c-1.178 0-2.097-1.056-2.097-2.362 0-1.307.92-2.359 2.096-2.359h4.211v-3.774h-4.21c-1.178 0-2.097-1.056-2.097-2.358s.92-2.363 2.096-2.363h4.211v-3.747h-4.21c-1.178 0-2.097-1.079-2.097-2.386 0-1.306.92-2.362 2.096-2.362h4.211v-3.77h-4.21c-1.178 0-2.097-1.043-2.097-2.358 0-1.316.92-2.363 2.096-2.363h4.211C6.845 1.908 6.767.488 14.578.725zm7.387 19.057c-.28 0-.549-.11-.747-.306a1.042 1.042 0 010-1.481c.198-.197.467-.307.747-.307H37.51c.28 0 .55.11.747.307a1.042 1.042 0 010 1.48 1.062 1.062 0 01-.747.307H21.965zm0-5.518a1.066 1.066 0 01-.705-.33 1.046 1.046 0 010-1.438c.184-.195.436-.313.705-.33H40.3a1.07 1.07 0 011.044.63 1.044 1.044 0 01-.248 1.187 1.064 1.064 0 01-.796.281H21.965zm-2.712-6.733h23.763c.406.002.794.163 1.08.447.287.283.449.668.451 1.069v13.877a1.526 1.526 0 01-.452 1.07 1.556 1.556 0 01-1.079.45H19.253a1.548 1.548 0 01-1.081-.448 1.518 1.518 0 01-.45-1.072v-13.9c.007-.398.172-.777.458-1.056.286-.279.671-.436 1.073-.437z"
        fill="#fff"
      />
    </Svg>
  );
}
