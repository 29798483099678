import React from 'react';
import { ShadowView } from '../../../../helperComponents/ShadowView';
import { StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import Fontisto from 'react-native-vector-icons/Fontisto';
import { ArrowRight } from '../../../../../resources/svgComponents/ArrowRight';
import { textStyles } from '../../../../../constants/textStyles';
import { Spacer } from '../../../../helperComponents/Spacer';
import GradientContainer from '../../../../helperComponents/gradients/GradientContainer';

type CardProps = {
    icon: React.ReactChild,
    color: string | [string, string],
    title: string,
    text: string,
    isLocked: boolean,
    onPress?: () => void
}

export default function Card({ icon, color, title, text, isLocked, onPress }: CardProps) {
    return (
        <ShadowView
            style={[styles.card, styles.cardContainer]}
            onPress={isLocked ? undefined : onPress}
        >
            <ShadowView style={{ borderRadius: 12 }}>
                <GradientContainer style={styles.icon} colors={typeof color === 'string' ? [color, color] : color}>
                    {icon}
                </GradientContainer>
            </ShadowView>
            <Spacer width={20} />
            <View style={styles.content}>
                <View style={styles.title}>
                    <Text style={styles.titleText}>{title}</Text>
                    {isLocked && <Fontisto name="locked" color="#EE3823" size={14} />}
                </View>
                <Text style={styles.textText}>{text}</Text>
            </View>
            <Spacer width={20} />
            <View>
                <ArrowRight />
            </View>
        </ShadowView>
    );
}

const styles = StyleSheet.create({
    card: {
        borderRadius: 10,
        justifyContent: 'center',
    },
    cardContainer: {
        padding: 20,
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        height: 62,
        width: 85,
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    title: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    titleText: mbTextStyles([textStyles.smallText, {
        color: '#292E30',
        fontSize: 18,
        fontWeight: '600',
        marginRight: 8,
        textAlign: 'left',
    }]),
    textText: mbTextStyles([textStyles.smallText, {
        color: '#677278',
        fontSize: 14,
        fontWeight: '400',
        marginTop: 5,
        textAlign: 'left',
    }]),
    content: {
        flex: 1,
    },
});
