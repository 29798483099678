import React, { Fragment, useMemo, useState } from 'react';
import { AnswerType, BadgeTypes, IdealQuestionSurveyResult, QuestionResultToView, ResultType } from '@sapphicsavvy/business';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import Layout from '../components/Layout';
import { Image, Platform, StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import SliderResultCard from '../components/SliderResultCard';
import { Spacer } from '../../../helperComponents/Spacer';
import { imageUtils } from '../../../../utils/imageUtils';
import { capitalize, getNumberOfSteps } from '../../../../utils/shared';
import MostIdealBadgeModal from '../modals/MostIdealBadgeModal';
import { ShadowView } from '../../../helperComponents/ShadowView';

type IdealBonusStepProps = {
    onNext?: () => void
    onBack?: () => void,
    points: number,
    results: QuestionResultToView
}

export default function IdealBonusStep({ points, onBack, onNext, results }: IdealBonusStepProps) {

    const { currentUserData } = useSignedInContext();
    const imageUri = mbGetMediumImage(results.imageUrl, { asUri: true });

    const userGuess = results.idealResult?.userGuess || 0;
    const surveyResult = results.idealResult?.surveyResult || 0;

    const [isBadgeModalVisible, setIsBadgeModalVisible] = useState(results.idealResult?.badge === BadgeTypes.idealAnswer);

    const resultColors = useMemo(() => {
        if (surveyResult > 50) {
            return ['#0295CD', '#6A2C8C'];
        }
        return ['#6A2C8C', '#0295CD'];
    }, [surveyResult]);

    const userColor = useMemo(() => {
        if (results.idealResult?.surveyTruth === IdealQuestionSurveyResult.yes) {
            return '#0295CD';
        }
        return '#6A2C8C';
    }, [results.idealResult?.surveyTruth]);

    const truth = useMemo(() => {
        if (results.idealResult?.surveyTruth === IdealQuestionSurveyResult.yes) {
            return 'Yes';
        } else if (results.idealResult?.surveyTruth === IdealQuestionSurveyResult.no) {
            return 'No';
        }
        return '50-50';
    }, [results.idealResult?.surveyTruth]);

    return (
        <Layout
            title="IDEAL BONUS"
            titleStyle={styles.title}
            points={points}
            numberOfSteps={getNumberOfSteps(results)}
            currentStep={4}
            onBack={onBack}
            onNext={onNext}
            nextButtonColor="#FCAF41F4"
            topSectionStyle={styles.topSection}
            titleColor="#FCAF41F4"
            topSectionChildren={
                <View style={styles.topSectionInfo}>
                    <Text style={styles.topSectionTitleText}>{capitalize(currentUserData?.identity || '')} ideal answer to...{'\n'}"<Text style={styles.topSectionTitleItalicText}>{results.guessQuestion}"</Text></Text>
                    <Image resizeMode={Platform.OS === 'ios' ? 'contain' : 'cover'} style={styles.topSectionImage} source={imageUri} />
                </View>
            }
            bottomSectionStyle={styles.bottomSection}
        >
            {results.answerType === AnswerType.numeric &&
                <Fragment>
                    <SliderResultCard
                        title="You"
                        type={results.answerType}
                        value={userGuess}
                        colors={['#0295CD', '#0295CD']}
                        image={
                            <View style={styles.imageConatiner}>
                                <Image style={styles.image} source={mbGetMediumImage(currentUserData?.avatarUrl, { asUri: true })} />
                            </View>
                        }
                        min={results.answerOptions.min}
                        max={results.answerOptions.max}
                    />
                    <Spacer height={15} />
                    <SliderResultCard
                        title="Truth"
                        type={results.answerType}
                        value={surveyResult}
                        colors={['#6A2C8C', '#6A2C8C']}
                        image={
                            <View style={styles.logoContainer}>
                                <Image style={styles.logo} source={imageUtils.screens.logoHomeScreen} resizeMode={Platform.OS === 'android' ? 'stretch' : 'contain'} />
                            </View>
                        }
                        min={results.answerOptions.min}
                        max={results.answerOptions.max}
                    />
                </Fragment>
            }
            {results.answerType === AnswerType.percentage &&
                <Fragment>
                    <View style={styles.statistics}>
                        <View style={[styles.statisticsLeftSection, { width: `${surveyResult}%` }]}>
                            <Text numberOfLines={1} style={[styles.statisticsLeftSectionTopText, { color: resultColors[0] }]}>Yes</Text>
                            <View style={[styles.statisticsLeftSectionContainer, { backgroundColor: resultColors[0] }]}>
                                <Text adjustsFontSizeToFit numberOfLines={1} style={styles.statisticsLeftSectionText}>{surveyResult}%</Text>
                            </View>
                        </View>
                        <View style={[styles.statisticsRightSection, { width: `${100 - surveyResult}%` }]}>
                            <Text style={[styles.statisticsRightSectionTopText, { color: resultColors[1] }]}>No</Text>
                            <View style={[styles.statisticsRightSectionContainer, { backgroundColor: resultColors[1] }]}>
                                <Text adjustsFontSizeToFit numberOfLines={1} style={styles.statisticsRightSectionText}>{100 - surveyResult}%</Text>
                            </View>
                        </View>
                    </View>
                    <ShadowView style={styles.resultInfo}>
                        <View style={styles.resultInfoRow}>
                            <View style={styles.imageContainer}>
                                <Image style={styles.image} source={mbGetMediumImage(currentUserData?.avatarUrl, { asUri: true })} />
                            </View>
                            <Text style={[styles.userGuessText, { color: userColor }]}>You: {userGuess ? 'Yes' : 'No'}</Text>
                        </View>
                        <Spacer height={15} />
                        <View style={styles.resultInfoRow}>
                            <View style={[styles.imageContainer, { backgroundColor: '#FFFFFF' }]}>
                                <Image style={styles.logo} source={imageUtils.screens.logoHomeScreen} resizeMode="contain" />
                            </View>
                            <Text style={[styles.userGuessText]}>Truth: {truth}</Text>
                        </View>
                    </ShadowView>
                </Fragment>
            }
            {results.idealResult?.resultType === ResultType.outOfRange &&
                <View style={styles.wrongAnswer}>
                    <Text style={styles.answerText}>GOOD LUCK NEXT TIME</Text>
                </View>
            }
            {results.idealResult?.resultType === ResultType.exact &&
                <View style={styles.correctAnswer}>
                    <Text style={styles.answerText}>YOU WON +{results.idealResult.bonusPoints} points</Text>
                </View>
            }
            {results.idealResult?.resultType === ResultType.inMargin &&
                <View style={styles.correctAnswer}>
                    <Text style={styles.answerText}>WITHIN RANGE!</Text>
                </View>
            }
            {results.idealResult?.resultType === ResultType.inWiggle &&
                <View style={styles.correctAnswer}>
                    <Text style={styles.answerText}>CLOSE ENOUGH!</Text>
                </View>
            }
            <MostIdealBadgeModal
                type={results.answerType}
                answer={userGuess}
                isVisible={isBadgeModalVisible}
                onDismiss={() => setIsBadgeModalVisible(false)}
            />
        </Layout>
    );
}

const styles = StyleSheet.create({
    title: {
        fontSize: 22,
    },
    topSection: {
        backgroundColor: '#FCAF41F4',
        height: '35%',
    },
    topSectionInfo: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flex: 1,
    },
    topSectionTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        fontFamily: FONT_STYLES.Inter_600_Italic,
        fontWeight: '600',
        marginBottom: 20,
    }]),
    topSectionTitleItalicText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        fontFamily: FONT_STYLES.Inter_600_Italic,
        fontWeight: '600',
        marginBottom: 20,
        fontStyle: 'italic',
    }]),
    topSectionImage: Platform.select({
        default: {
            flex: 1,
            alignSelf: 'stretch',
            borderRadius: 12,
            marginHorizontal: 10,
        },
        android: {
            flex: 1,
            aspectRatio: 2,
            borderRadius: 12,
            marginHorizontal: 10,
            alignSelf: 'center',
        },
    }),
    bottomSection: {
        paddingHorizontal: 30,
        justifyContent: 'flex-start',
        paddingTop: 20,
    },
    imageConatiner: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 4,
        borderRadius: 80,
        borderColor: '#FFFFFF',
    },
    image: {
        width: 55,
        height: 55,
        borderRadius: 80,
    },
    logoContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 4,
        borderRadius: 80,
        borderColor: 'transparent',
    },
    logo: {
        width: 55,
        height: 55,
    },

    // # statistics
    statistics: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        marginTop: 20,
    },
    statisticsLeftSection: {
        alignItems: 'stretch',
        justifyContent: 'center',
        overflow: 'visible',
    },
    statisticsLeftSectionContainer: {
        borderTopLeftRadius: 22,
        borderBottomLeftRadius: 22,
        backgroundColor: '#0295CD',
        height: 55,
        alignItems: 'center',
        justifyContent: 'center',
    },
    statisticsLeftSectionText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontWeight: '700',
        color: '#FFFFFF',
    }]),
    statisticsLeftSectionTopText: mbTextStyles([textStyles.largeText, {
        fontSize: 17,
        fontWeight: '700',
        color: '#0295CD',
        position: 'absolute',
        top: -25,
        width: 35,
        alignSelf: 'center',
    }]),
    statisticsRightSection: {
        alignItems: 'stretch',
        justifyContent: 'center',
        overflow: 'visible',
    },
    statisticsRightSectionContainer: {
        borderTopRightRadius: 22,
        borderBottomRightRadius: 22,
        backgroundColor: '#6A2C8C',
        height: 55,
        alignItems: 'center',
        justifyContent: 'center',
    },
    statisticsRightSectionText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontWeight: '700',
        color: '#FFFFFF',
    }]),
    statisticsRightSectionTopText: mbTextStyles([textStyles.largeText, {
        fontSize: 17,
        fontWeight: '700',
        color: '#6A2C8C',
        position: 'absolute',
        top: -25,
        width: 35,
        alignSelf: 'center',
    }]),
    // #! statistics

    resultInfo: {
        marginTop: 15,
        alignSelf: 'stretch',
        padding: 15,
    },
    resultInfoRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    imageContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderRadius: 80,
        borderColor: '#FFFFFF',
        marginRight: 10,
    },
    userGuessText: mbTextStyles([textStyles.largeText, {
        fontSize: 18,
        fontWeight: '600',
        color: '#0295CD',
    }]),



    wrongAnswer: {
        backgroundColor: '#EE3823',
        position: 'absolute',
        left: 0,
        right: 0,
        paddingVertical: 15,
        alignItems: 'center',
        justifyContent: 'center',
        bottom: 10,
    },
    answerText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
    }]),
    correctAnswer: {
        backgroundColor: '#0295CD',
        position: 'absolute',
        left: 0,
        right: 0,
        paddingVertical: 15,
        alignItems: 'center',
        justifyContent: 'center',
        bottom: 10,
    },
});
