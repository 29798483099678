import * as React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const Profile2Icon = React.memo(
    (
        props: {
            size?: number;
            color?: string;
            containerStyle?: StyleProp<ViewStyle>;
        } & React.ComponentProps<typeof Svg>,
    ) => {
        const { size, color, containerStyle, ...svgProps } = props;
        const iconSize = size ?? 25;

        return (
            <View style={containerStyle}>
                <Svg
                    width={iconSize}
                    height={iconSize}
                    viewBox="0 0 25 25"
                    fill="none"
                    {...svgProps}
                >
                    <Path
                        d="M12.66 11.393c-.1-.01-.22-.01-.33 0a4.42 4.42 0 01-4.27-4.43c0-2.45 1.98-4.44 4.44-4.44a4.435 4.435 0 01.16 8.87zM7.66 15.083c-2.42 1.62-2.42 4.26 0 5.87 2.75 1.84 7.26 1.84 10.01 0 2.42-1.62 2.42-4.26 0-5.87-2.74-1.83-7.25-1.83-10.01 0z"
                        fill={color ?? '#F35E2E'}
                        stroke={color ?? '#F35E2E'}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </Svg>
            </View>
        );
    },
);

Profile2Icon.displayName = 'Profile2Icon';

export default Profile2Icon;
