import React, { useMemo } from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { StyleSheet, Text, View } from 'react-native';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import BadgeOverview from '../../../../resources/svgComponents/Badges/BadgeOverview';
import { Spacer } from '../../../helperComponents/Spacer';
import { AnswerType } from '@sapphicsavvy/business';

export type MostIdealBadgeModalProps = {
    type: AnswerType,
    isVisible: boolean,
    onDismiss?: () => void,
    answer: number,
}

export default function MostIdealBadgeModal({ isVisible, onDismiss, answer, type }: MostIdealBadgeModalProps) {

    const answerText = useMemo(() => {
        if (type === AnswerType.percentage) {
            return answer === 1 ? 'Yes' : 'No';
        }
        return `${answer}`;
    }, [answer, type]);

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            title="New Badge Unlocked"
            titleStyle={styles.titleText}
            closeButtonXProps={{ color: '#FFFFFF' }}
            childrenWrapperStyle={styles.container}
            withoutScrollView
        >
            <View style={styles.content}>
                <Text style={styles.preHeadLineText}>You answered ‘{answerText}’ which was</Text>
                <View style={styles.badgePrize}>
                    <Text style={styles.headLineText}>MOST IDEAL</Text>
                    <Spacer height={26} />
                    <BadgeOverview />
                    <Spacer height={26} />
                    <Text style={styles.noteText}>Congrats! new profile badge unlocked</Text>
                </View>
            </View>
        </MB_Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: mbApplyTransparency('#FCAF41', 0.95),
        width: '90%',
        paddingHorizontal: 0,
        paddingBottom: 0,
        paddingTop: 48,
    },
    titleText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '600',
    }]),
    content: {
        backgroundColor: '#6A2C8C',
        width: '100%',
        paddingHorizontal: 10,
        paddingVertical: 40,
    },
    preHeadLineText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontWeight: '400',
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#FFFFFF',
    }]),
    badgePrize: {
        paddingHorizontal: 50,
        alignItems: 'center',
    },
    headLineText: mbTextStyles([textStyles.largeText, {
        fontSize: 42,
        fontWeight: '400',
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#FFFFFF',
    }]),
    noteText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        color: '#FFFFFF',
    }]),
});



