import { z } from 'zod';
import { NotificationTargets, NotificationTypes } from '../../../entities';
import {
    DateInputSchema,
    StringSchema,
    TimeInputSchema,
    EmailSchema,
} from '../../common';

export const UpdateNotificationSchema = z.object({
    type: z.nativeEnum(NotificationTypes),
    isActive: z.boolean(),
    customMessage: StringSchema.transform((value) =>
        value.replace(/\s+/g, ''),
    ).optional(),
    customTitle: StringSchema.transform((value) =>
        value.replace(/\s+/g, ''),
    ).optional(),
    target: z
        .nativeEnum(NotificationTargets)
        .default(NotificationTargets.all)
        .optional(),
    sendAtDate: DateInputSchema.optional(),
    sendAtTime: TimeInputSchema.optional(),
});

export const UpdateNotificationTemplatesInputSchema = z.object({
    data: z.array(UpdateNotificationSchema),
});

export const TestNotificationInputSchema = z.object({
    phoneNumber: StringSchema,
    type: z.nativeEnum(NotificationTypes),
    customMessage: StringSchema.optional(),
    customTitle: StringSchema.optional(),
});
