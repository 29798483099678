import React, { useCallback, useState } from 'react';
import { ShapeCategories, shapeCategoryToLabel } from '@sapphicsavvy/business';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { FlatList, ListRenderItemInfo, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import SlidersIcon from '../../../../resources/svgComponents/SlidersIcon';
import { hitSlop } from '../../../../utils/shared';
import XIcon from '../../../../resources/svgComponents/XIcon';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { ShadowView } from '../../../helperComponents/ShadowView';
import ShadowCheckbox from '../../../helperComponents/ShadowCheckbox';
import { Spacer } from '../../../helperComponents/Spacer';
import { GradientButton } from '../../../helperComponents/gradients/GradientButton';

type FilterModalProps = {
    isVisible: boolean,
    onDismiss?: (categories?: ShapeCategories[]) => void,
    selectedCategories?: ShapeCategories[],
}

export default function FilterModal({ isVisible, onDismiss, selectedCategories }: FilterModalProps) {

    const [categories, setCategories] = useState<ShapeCategories[]>([]);

    const onModalWillShow = useCallback(() => {
        setCategories(selectedCategories ?? []);
    }, [selectedCategories]);

    const keyExtractor = useCallback((item: ShapeCategories) => item, []);
    const itemSeparator = useCallback(() => <Spacer height={12} />, []);

    const onSelectItem = useCallback((item: ShapeCategories) => {
        if (categories.includes(item)) {
            setCategories(categories.filter((category) => category !== item));
        } else {
            setCategories([...categories, item]);
        }
    }, [categories]);

    const renderItem = useCallback(({ item }: ListRenderItemInfo<ShapeCategories>) => {
        const isSelected = categories.includes(item);
        return (
            <ShadowView style={styles.item}>
                <ShadowCheckbox
                    value={isSelected}
                    text={shapeCategoryToLabel(item)}
                    style={styles.checkboxContainer}
                    textStyle={[styles.checkboxText, isSelected && { color: '#F35E2E' }]}
                    checkBoxStyle={styles.checkbox}
                    checkColor="#000000"
                    onChange={() => onSelectItem(item)}
                />
            </ShadowView>
        );
    }, [categories, onSelectItem]);

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={() => onDismiss?.()}
            hideCloseButton
            childrenWrapperStyle={styles.container}
            withoutScrollView
            onModalWillShow={onModalWillShow}
        >
            <View style={styles.header}>
                <SlidersIcon color="#FFFFFF" size={31} />
                <Text style={styles.headerTitletext}>Filter Categories</Text>
                <TouchableOpacity
                    onPress={() => onDismiss?.()}
                    hitSlop={hitSlop}
                >
                    <XIcon size={22} color="#FFFFFF" />
                </TouchableOpacity>
            </View>
            <FlatList
                data={Object.values(ShapeCategories)}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                ItemSeparatorComponent={itemSeparator}
                style={styles.flatList}
                contentContainerStyle={styles.flatListContentContainer}
            />
            <View style={styles.footer}>
                <GradientButton
                    title="ADD"
                    containerStyle={styles.footerBtnContainer}
                    style={styles.footerBtn}
                    textStyle={styles.footerBtnText}
                    onPress={() => onDismiss?.(categories)}
                />
                <Spacer width={9} />
                <GradientButton
                    title="CANCEL"
                    colors={['#FFFFFF', '#FFFFFF']}
                    containerStyle={styles.footerBtnContainer}
                    style={styles.footerBtn}
                    textStyle={[styles.footerBtnText, { color: '#F35E2E' }]}
                    onPress={() => onDismiss?.()}
                />
            </View>
        </MB_Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#EFEFF3',
        width: '90%',
        paddingHorizontal: 0,
        paddingTop: 0,
        paddingBottom: 20,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
        padding: 20,
        backgroundColor: '#F35E2E',
    },
    headerTitletext: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        color: '#FFFFFF',
        marginRight: 'auto',
        marginLeft: 17,
    }]),
    flatList: {
        alignSelf: 'stretch',
    },
    flatListContentContainer: {
        padding: 15,
    },
    item: {
        paddingHorizontal: 19,
        paddingVertical: 18,
    },
    checkboxContainer: {
        alignItems: 'center',
    },
    checkbox: {
        borderWidth: 1,
        borderColor: '#000000',
        borderRadius: 0,
    },
    checkboxText: mbTextStyles([textStyles.normalText, {
        color: '#334253',
    }]),
    footer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignSelf: 'stretch',
        alignItems: 'stretch',
        paddingHorizontal: 15,
    },
    footerBtnContainer: {
        flex: 1,
        height: 48,
    },
    footerBtn: {
        height: 48,
    },
    footerBtnText: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        color: '#FFFFFF',
        fontWeight: '800',
    }]),
});
