import { z } from 'zod';
import { DateInputSchema, EmailSchema, StringSchema } from '../common';

import { UserIdentity, UniqueUserFields, UserSelectTags } from '../../entities';

const UserNameSchema = z.string().min(2).max(10);

export const CreateUserInputSchema = z.object({
    phoneNumber: StringSchema,
    password: z.string(),
    confirmPassword: z.string(),
});

export const CreateAdminInputSchema = z.object({
    phoneNumber: StringSchema,
    userName: UserNameSchema,
    password: z.string(),
    confirmPassword: z.string(),
});

export const LoginInputSchema = z.object({
    phoneNumber: StringSchema,
    password: z.string(),
});

export const RefreshAccessTokenInputSchema = z.object({
    accessToken: z.string(),
});

export const ChangePasswordInputSchema = z.object({
    currentPassword: z.string(),
    newPassword: z.string(),
    confirmPassword: z.string(),
});

export const ChangePasswordViaRecoveryTokenInputSchema = z.object({
    recoveryToken: z.string(),
    code: z.string(),
    newPassword: z.string(),
});

export const VerifyPhoneNumberInputSchema = z.object({
    code: z.string(),
    token: z.string(),
});

export const ResendPhoneVerificationCodeInputSchema = z.object({
    phoneNumber: StringSchema,
});

export const PasswordRecoveryRequestInputSchema = z.object({
    phoneNumber: StringSchema,
});

export const UpdateCurrentUserDataInputSchema = z.object({
    userName: UserNameSchema.optional(),
    identity: z.nativeEnum(UserIdentity).optional(),
    dob: DateInputSchema.optional(),
    email: EmailSchema.optional(),
    avatarFilename: StringSchema.optional(),
    bio: z.string().optional(),
    socialMedia: z
        .object({
            instagram: StringSchema.optional(),
            tiktok: StringSchema.optional(),
            twitter: StringSchema.optional(),
        })
        .optional(),
    location: StringSchema.optional(),
    tags: z.array(z.nativeEnum(UserSelectTags)).max(6).optional(),
});

export const SetupAccountInputSchema = z.object({
    userName: UserNameSchema,
    identity: z.nativeEnum(UserIdentity),
    dob: DateInputSchema,
    email: EmailSchema,
    avatarFilename: StringSchema,
    referralCode: z.string().optional(),
    location: StringSchema.optional(),
});

export const IsUniqueUserFieldInputSchema = z.object({
    fieldName: z.nativeEnum(UniqueUserFields),
    value: z.string(),
});

export const AddFirebaseDeviceTokenInputSchema = z.object({
    firebaseToken: z.string(),
    deviceId: z.string(),
});

export const DeviceIsRegisteredInputSchema = z.object({
    deviceId: StringSchema,
});
