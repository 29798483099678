import React, { useCallback, useMemo, useRef, useState } from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { Dimensions, Image, Linking, NativeScrollEvent, NativeSyntheticEvent, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { GradientContainer } from '../../../../helperComponents/gradients/GradientButton';
import XIcon from '../../../../../resources/svgComponents/XIcon';
import { getTopInset, hitSlop } from '../../../../../utils/shared';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { KeywordView } from '../../../../helperComponents/Keywords';
import { Spacer } from '../../../../helperComponents/Spacer';
import { FONT_STYLES, textStyles } from '../../../../../constants/textStyles';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import Feather from 'react-native-vector-icons/Feather';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import StarIcon from '../../../../../resources/svgComponents/StarIcon';
import { imageUtils } from '../../../../../utils/imageUtils';
import SocialXIcon from '../../../../../resources/svgComponents/SocialXIcon';

const stars: [number | string, number | string, number][] = [
    ['27%', '4%', 19],
    ['6%', '36%', 35],
    ['9%', '62%', 19],
    ['13%', '92%', 19],
    ['23%', '55%', 57],
    ['50%', '80%', 19],
    ['94%', '4%', 35],
    ['90%', '14%', 19],
    ['76%', '36%', 19],
    ['85%', '62%', 19],
    ['90%', '86%', 35],
];

const renderRandomStars = (points: [number | string, number | string, number][]) => (
    <React.Fragment>
        {points.map(point => (
            <View key={JSON.stringify(point)} style={{ position: 'absolute', top: point[1], left: point[0] }}>
                <StarIcon size={point[2]} />
            </View>
        ))}
    </React.Fragment>
);


export type SapphicProfileProps = {
    userId?: string,
    onDismiss?: () => void,
    onModalHide?: () => void,
}

const SCREEN_HEIGHT = Dimensions.get('window').height - getTopInset();
const HALF_SCREEN_HEIGHT = SCREEN_HEIGHT / 2;

export default function SapphicProfile({ userId, onDismiss, onModalHide }: SapphicProfileProps) {

    const { data: userData } = trpc.sapphics.getUserById.useQuery({ id: userId as string }, { enabled: Boolean(userId) });

    const scrollViewRef = useRef<ScrollView | null>(null);
    const [scrollOffsetY, setScrollOffsetY] = useState(0);
    const calcStyles = useMemo(() => ({
        sectionTop: {
            zIndex: scrollOffsetY <= 5 ? 1 : -1,
        },
        backdrop: {
            backgroundColor: mbApplyTransparency('#000000', scrollOffsetY <= 5 ? 0 : (scrollOffsetY * 1.2) / HALF_SCREEN_HEIGHT),
        },
    }), [scrollOffsetY]);

    const onBackdrop = useCallback(() => scrollViewRef.current?.scrollTo({ y: 0, animated: true }), []);
    const onScroll = useCallback((e: NativeSyntheticEvent<NativeScrollEvent>) => setScrollOffsetY(e.nativeEvent.contentOffset.y), []);

    const tags: string[] = useMemo(() => {
        if (userData) {
            return [...userData.defaultTags, ...userData.tags];
        }
        return new Array(3).fill('loading');
    }, [userData]);

    const twitter = userData?.socialMedia?.twitter;
    const tiktok = userData?.socialMedia?.tiktok;
    const instagram = userData?.socialMedia?.instagram;

    const hasTwitter = Boolean(twitter);
    const hasTiktok = Boolean(tiktok);
    const hasInstagram = Boolean(instagram);

    return (
        <MB_Modal
            isVisible={userId !== undefined}
            onDismiss={() => onDismiss?.()}
            hideCloseButton
            childrenWrapperStyle={styles.container}
            withoutSafeArea
            statusBarTranslucent
            withoutScrollView
            onModalHide={onModalHide}
        >
            <GradientContainer style={styles.content} angle={-90}>
                <View style={[styles.section, styles.sectionTop, calcStyles.sectionTop]}>
                    {renderRandomStars(stars)}
                    <View style={styles.imageConatiner}>
                        <Image style={styles.image} source={{ uri: mbGetMediumImage(userData?.avatarUrl) }} />
                    </View>
                    <Text style={styles.username}>{userData?.userName?.toUpperCase() ?? 'Loading...'}</Text>
                    {(hasTwitter || hasTiktok || hasInstagram) &&
                        <Spacer height={10} />
                    }
                    <View style={styles.social}>
                        {hasTwitter &&
                            <TouchableOpacity style={styles.socialItem} onPress={() => Linking.openURL(`https://www.x.com/${twitter}`)}>
                                <SocialXIcon size={30} background="transparent" />
                            </TouchableOpacity>
                        }
                        {hasTiktok &&
                            <TouchableOpacity style={styles.socialItem} onPress={() => Linking.openURL(`https://www.tiktok.com/@${tiktok}`)}>
                                <FontAwesome5 name="tiktok" size={21} color="#FFFFFF" />
                            </TouchableOpacity>
                        }
                        {hasInstagram &&
                            <TouchableOpacity style={styles.socialItem} onPress={() => Linking.openURL(`https://www.instagram.com/${instagram}`)}>
                                <Feather name="instagram" size={21} color="#FFFFFF" />
                            </TouchableOpacity>
                        }
                    </View>
                    <TouchableOpacity
                        hitSlop={hitSlop}
                        onPress={() => onDismiss?.()}
                        style={styles.close}
                    >
                        <XIcon size={20} color="#FFFFFF" />
                    </TouchableOpacity>
                </View>
                <View style={styles.section} />
                <ScrollView
                    ref={scrollViewRef}
                    style={[styles.scrollSection]}
                    contentContainerStyle={styles.sectionBottomScrollContent}
                    showsVerticalScrollIndicator={false}
                    onScroll={onScroll}
                    scrollEventThrottle={10}
                >
                    <TouchableOpacity
                        style={[styles.backdrop, calcStyles.backdrop]}
                        onPress={onBackdrop}
                        activeOpacity={1}
                    />
                    <View style={styles.sectionBottomContent}>
                        <View style={styles.badges}>
                            <Image style={styles.badge} source={imageUtils.badges.star} />
                            <Spacer width={18} />
                            <Image style={styles.badge} source={imageUtils.badges.calendar} />
                            <Spacer width={18} />
                            <Image style={styles.badge} source={imageUtils.badges.sound} />
                        </View>
                        <View style={styles.tags}>
                            {tags.map((item, index) => (
                                <KeywordView key={`${item}-${index}`} value={item} style={styles.tag} />
                            ))}
                        </View>
                        <Spacer height={35} />
                        <View style={styles.bio}>
                            <Text style={styles.bioTitleText}>Bio</Text>
                            <Text style={styles.bioText}>{userData?.bio}</Text>
                        </View>
                    </View>
                    <View style={styles.scrollSectionBottom} />
                </ScrollView>
            </GradientContainer>
        </MB_Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#f0f',
        marginVertical: 0,
        width: '100%',
        height: '100%',
        paddingHorizontal: 0,
        paddingBottom: 0,
        paddingTop: 0,
    },
    content: {
        width: '100%',
        height: '100%',
        paddingTop: getTopInset(),
    },
    close: {
        position: 'absolute',
        right: 28,
        top: 20,
    },
    section: {
        flex: 1,
    },
    sectionTop: {
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
    },
    imageConatiner: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderRadius: 80,
        borderColor: '#FFFFFF',
        alignSelf: 'center',
    },
    image: {
        width: 158,
        height: 158,
        borderRadius: 80,
    },
    username: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        fontSize: 35,
        textAlign: 'center',
        color: '#FFFFFF',
        marginTop: 10,
    }]),
    social: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    socialItem: {
        backgroundColor: '#EE3823',
        width: 40,
        height: 40,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 8,
    },
    socialItemLeft: {
        marginLeft: 19,
    },
    socialItemRight: {
        marginRight: 19,
    },
    scrollSection: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    scrollSectionBottom: {
        position: 'absolute',
        bottom: -1000,
        left: 0,
        right: 0,
        height: 1000,
        backgroundColor: '#EEEEF2',
    },
    sectionBottomScrollContent: {
        paddingTop: getTopInset() + HALF_SCREEN_HEIGHT - 18,
        flexGrow: 1,
    },
    backdrop: {
        backgroundColor: '#000000',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        position: 'absolute',
    },
    sectionBottomContent: {
        borderTopLeftRadius: 36,
        borderTopRightRadius: 36,
        backgroundColor: '#EEEEF2',
        paddingVertical: 40,
        flexGrow: 1,
        ...mbShadow({
            offsetWidth: -5,
            offsetHeight: 0,
            color: '#000000',
            opacity: 0.15,
            radius: 10,
            elevation: 2,
        }),
    },
    badges: {
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'absolute',
        top: -28,
        alignSelf: 'center',
        zIndex: 1,
    },
    badge: {
        width: 59,
        height: 59,
    },
    tags: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        backgroundColor: '#FFF0EF',
        paddingVertical: 14,
        paddingHorizontal: 27,
        paddingBottom: 14 + 21,
    },
    tag: {
        marginRight: 17,
        marginTop: 21,
        backgroundColor: '#FFF0EF',
    },
    bio: {
        paddingHorizontal: 30,
    },
    bioTitleText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '600',
        color: '#F14828',
        textAlign: 'left',
    }]),
    bioText: mbTextStyles([textStyles.smallText, {
        fontWeight: '400',
        color: '#000000',
        textAlign: 'left',
    }]),
});
