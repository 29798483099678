import { ChatRoomTypes } from '@sapphicsavvy/business';
import { Text, View } from 'react-native';
import React, { useEffect, useState, useCallback } from 'react';
import { ComponentWrapper } from '../../../../helperComponents/componentWrapper/ComponentWrapper';
import * as TalkRn from '@talkjs/react-native';
import { useSignedInContext } from '../../../../../context/SignedInContext';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { LoadingPage } from '../../../../helperComponents/LoadingPage';
import { ChatroomHeader } from './ChatroomHeader';

interface Props {
    roomType: ChatRoomTypes;
    onClose: () => void;
    joinLoading: boolean;
}

export function ChatRoom({ roomType, onClose, joinLoading }: Props) {
    const { currentUserData } = useSignedInContext();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [conversationBuilder, setConversationBuilder] = useState<any>(null);
    const { data } = trpc.chat.getToken.useQuery();
    const { data: participants } = trpc.chat.getRoomParticipants.useQuery({
        roomType,
    });

    const getToken = useCallback(async () => {
        return data?.token || '';
    }, [data]);

    const me = {
        id: currentUserData?.id || 'default_id',
        name: currentUserData?.userName || 'default_user',
        email: currentUserData?.email || 'default@example.com',
        photoUrl: (currentUserData?.avatarUrl || null) as string | null,
    };

    useEffect(() => {
        try {
            const builder = TalkRn.getConversationBuilder(roomType);
            setConversationBuilder(builder);

            // prevents chat from crashing
            setTimeout(() => {
                setLoading(false);
            }, 300);
        } catch (err) {
            console.error('Error creating conversation builder:', err);
            setError('Failed to initialize chat. Please try again.');
            setLoading(false);
        }
    }, []);

    if (error) {
        return (
            <ComponentWrapper>
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Text>{error}</Text>
                </View>
            </ComponentWrapper>
        );
    }

    return (
        <ComponentWrapper>
            <ChatroomHeader
                title={roomType.toUpperCase()}
                onPress={onClose}
                userCount={participants?.count || 0}
            />
            <View
                style={{
                    flex: 1,

                    justifyContent: 'center',
                }}
            >
                {loading || joinLoading ? (
                    <LoadingPage />
                ) : (
                    <TalkRn.Session
                        appId="tioKIpoW"
                        me={me}
                        tokenFetcher={getToken}
                    >
                        {conversationBuilder && (
                            <TalkRn.Chatbox
                                conversationBuilder={conversationBuilder}
                                showChatHeader={true}
                                loadingComponent={<LoadingPage />}
                                disableZoom
                            />
                        )}
                    </TalkRn.Session>
                )}
            </View>
        </ComponentWrapper>
    );
}
