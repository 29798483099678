import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { capitalize } from '../../../../utils/shared';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { Spacer } from '../../../helperComponents/Spacer';
import { ShadowView } from '../../../helperComponents/ShadowView';
import CirculaireSlider from '../../../helperComponents/CirculaireSlider';
import ShadowRoundedButton from '../../../../resources/svgComponents/ShadowRoundedButton';
import MinusSignIcon from '../../../../resources/svgComponents/Quarter/MinusSignIcon';
import PlusSignIcon from '../../../../resources/svgComponents/Quarter/PlusSignIcon';
import { AnswerType } from '@sapphicsavvy/business';
import BarSlider from '../../../helperComponents/BarSlider';
import { textStyles } from '../../../../constants/textStyles';

type SliderCardProps = {
    title: string
    value: number,
    onChange?: (value: number) => void,
    steps?: number,
    min?: number,
    max?: number,
    answerType: AnswerType,
    strictMax?: boolean,
}

export default function RowSliderCard({ title, value, onChange, steps = 5, min = 0, max = 100, answerType, strictMax }: SliderCardProps) {
    const formattedValue = (value === max && !strictMax) ? `${value}+` : value;
    return (
        <ShadowView style={[styles.sliderCard, answerType === AnswerType.numeric && { paddingVertical: 25 }]}>
            <Text style={styles.sliderCardText}>{capitalize(title)}</Text>
            <View>
                {answerType === AnswerType.numeric ?
                    <BarSlider
                        colors={[mbApplyTransparency('#FCAF41', 0.95), mbApplyTransparency('#FCAF41', 0.95)]}
                        value={value}
                        minValue={min}
                        maxValue={max}
                        steps={steps}
                        onChange={onChange}
                        // disableLimit
                        size={14}
                        showPlus={!strictMax}
                    />
                    :
                    <CirculaireSlider
                        colors={[mbApplyTransparency('#FCAF41', 0.95), mbApplyTransparency('#FCAF41', 0.95)]}
                        percentage={value}
                        size={150}
                        onChange={onChange}
                        step={steps}
                    />
                }
                {answerType === AnswerType.percentage &&
                    <Text style={[styles.sliderCardValue, { marginTop: -40, marginBottom: 5 }]}>{value}%</Text>
                }
                <View style={[styles.sliderCardBtns, answerType === AnswerType.numeric && { marginTop: 15 }]}>
                    <ShadowRoundedButton
                        size={44}
                        borderWidth={10}
                        icon={<MinusSignIcon size={15} />}
                        onPress={() => onChange?.(Math.max(value - steps, min))}
                    />
                    {answerType === AnswerType.numeric ?
                        <Text style={[styles.sliderCardValue, { width: 70 }]}>{formattedValue}</Text>
                        :
                        <Spacer width={15} />
                    }
                    <ShadowRoundedButton
                        size={44}
                        borderWidth={10}
                        icon={<PlusSignIcon size={15} />}
                        onPress={() => onChange?.(Math.min(value + steps, max))}
                    />
                </View>
            </View>
        </ShadowView>
    );
}

const styles = StyleSheet.create({
    sliderCard: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 30,
        paddingVertical: 20,
    },
    sliderCardText: mbTextStyles([textStyles.largeText, {
        fontSize: 20,
        fontWeight: '600',
        color: mbApplyTransparency('#000000', 0.95),
    }]),
    sliderCardValue: mbTextStyles([textStyles.largeText, {
        fontSize: 25,
        fontWeight: '700',
        color: mbApplyTransparency('#000000', 0.95),
        zIndex: -1,
        marginHorizontal: 10,
    }]),
    sliderCardBtns: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
