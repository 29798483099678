import React, { Fragment, useCallback } from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import { ComponentWrapper } from '../../../../helperComponents/componentWrapper/ComponentWrapper';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { ChatRoomTypes } from '@sapphicsavvy/business';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../../../../navigations/RootNavigator';
import { useNavigation } from '@react-navigation/core';
import { ScreenProps } from '../../../../../typesAndInterfaces/componentProps';
import { ChatRoom } from './ChatRoom';
import OveviewScreen from '../../../../helperComponents/OveviewScreen';
import { FONT_STYLES, textStyles } from '../../../../../constants/textStyles';
import PeopleIcon from '../../../../../resources/svgComponents/PeopleIcon';
import { mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import Profile2Icon from '../../../../../resources/svgComponents/Profile2Icon';
import { debounce } from 'lodash';

export function Socialize({ route }: ScreenProps<'Socialize'>) {
    const { data } = trpc.chat.getParticipantsForAllRooms.useQuery();
    const { mutate: join, isLoading: joinLoading } =
        trpc.chat.join.useMutation();
    const { mutate: leave } = trpc.chat.leave.useMutation();
    const navigation =
        useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const params = route.params;

    const onNavigate = (roomType: ChatRoomTypes) => {
        navigation.setParams({ id: roomType });
    };

    const clearParams = () => {
        navigation.setParams({ id: undefined });
    };

    const joinRoom = (roomType: ChatRoomTypes) => {
        onNavigate(roomType);
        join({ roomType });
    };

    const leaveRoom = (roomType: ChatRoomTypes) => {
        clearParams();
        leave({ roomType });
    };

    const debouncedJoinRoom = useCallback(
        debounce((roomType: ChatRoomTypes) => {
            joinRoom(roomType);
        }, 300),
        [joinRoom],
    );

    if (params?.id) {
        return (
            <ChatRoom
                roomType={params.id}
                onClose={() => leaveRoom(params.id!)}
                joinLoading={joinLoading}
            />
        );
    }

    return (
        <OveviewScreen
            showBackBtn
            topSectionStyle={styles.topSection}
            topSectionChildren={
                <Fragment>
                    <PeopleIcon size={49} />
                    <Text style={styles.titleText}>SOCIALIZE</Text>
                    <Text style={styles.subTitleText}>Sapphic chat groups</Text>
                </Fragment>
            }
            bottomSectionStyle={styles.bottomSection}
        >
            <View style={styles.shape}>
                <ComponentWrapper>
                    <View style={styles.contentContainer}>
                        {data?.map((room) => (
                            <TouchableOpacity
                                key={room.roomType}
                                onPress={() => debouncedJoinRoom(room.roomType)}
                                style={styles.roomButton}
                            >
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={styles.roomTypeText}>
                                        {room.roomType.toUpperCase()}
                                    </Text>
                                    <Profile2Icon
                                        containerStyle={{ marginTop: 2 }}
                                    />
                                    <Text style={styles.roomParticipantsText}>
                                        {room.participants || 0}
                                    </Text>
                                </View>
                                <View style={styles.joinWrapper}>
                                    <Text style={styles.joinText}>Join</Text>
                                </View>
                            </TouchableOpacity>
                        ))}
                    </View>
                </ComponentWrapper>
            </View>
        </OveviewScreen>
    );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
    topSection: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20,
        height: '35%',
    },
    bottomSection: {
        height: '65%',
        paddingTop: 0,
        backgroundColor: '#EEEEF2',
    },
    shape: {
        flex: 1,
        borderTopLeftRadius: 36,
        borderTopRightRadius: 36,
        overflow: 'hidden',
        backgroundColor: '#EEEEF2',
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    titleText: mbTextStyles([
        textStyles.largestText,
        {
            fontSize: 45,
            fontFamily: FONT_STYLES.Tabasco_400,
            color: '#FFFFFF',
            fontWeight: '400',
            marginBottom: 10,
            marginTop: 5,
        },
    ]),
    joinText: mbTextStyles([
        textStyles.largestText,
        {
            fontSize: 18,
            fontFamily: FONT_STYLES.Inter_600,
            color: '#FFFFFF',
        },
    ]),
    joinWrapper: {
        paddingVertical: 10,
        paddingHorizontal: 15,
        backgroundColor: '#F35E2E',
        borderRadius: 29,
    },
    subTitleText: mbTextStyles([
        textStyles.largeText,
        {
            color: '#FFFFFF',
            fontSize: 18,
            fontWeight: '700',
            marginBottom: 20,
        },
    ]),
    contentContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    roomButton: {
        marginVertical: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 20,
        backgroundColor: '#F0F0F3',
        borderRadius: 999,
        ...mbShadow({
            offsetHeight: 2,
            offsetWidth: 2,
            color: '#565656',
            opacity: 0.1,
            elevation: 10,
            radius: 20,
        }),
        width: width * 0.9,
    },
    roomTypeText: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#F35E2E',
        fontFamily: FONT_STYLES.Tabasco_400,
        marginRight: 6,
    },
    roomParticipantsText: {
        fontSize: 20,
        color: '#1C1D20F4',
        fontFamily: FONT_STYLES.Tabasco_400,
        marginTop: 2,
    },
});
