import React, { useCallback } from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { getTopInset, hitSlop } from '../../../../utils/shared';
import { Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import XIcon from '../../../../resources/svgComponents/XIcon';
import { Spacer } from '../../../helperComponents/Spacer';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import { ShadowTextInput } from '../../../helperComponents/ShadowTextInput';
import { trpc } from '../../../../apiCalls/trpcClient';
import { AnswerType, ShapeCategories, shapeCategoryToLabel } from '@sapphicsavvy/business';
import DropdownPicker from '../../../helperComponents/DropdownPicker';
import { GradientButton } from '../../../helperComponents/gradients/GradientButton';
import { utils } from '../../../../utils/utils';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { z } from 'zod';
import { useForm } from '../../../../hooks/useForm';

function Separator() {
    return (
        <View style={styles.separator}>
            <View style={styles.separatorTopLine} />
            <View style={styles.separatorBottomLine} />
        </View>
    );
}

type CheckBoxProps = {
    title?: string,
    isActive?: boolean,
    onPress?: () => void,
}

function CheckBox({ title, isActive, onPress }: CheckBoxProps) {
    return (
        <View style={styles.checkBoxContainer}>
            <TouchableOpacity style={styles.checkBox} onPress={onPress} activeOpacity={1}>
                {isActive && <View style={styles.checkBoxActive} />}
            </TouchableOpacity>
            {title && <Text style={styles.checkBoxText}>{title}</Text>}
        </View>
    );
}

type ValidForm = {
    answerType: AnswerType,
    showSubmitter: boolean,
    question: string,
    category?: ShapeCategories,
}

const schemas = {
    answerType: z.nativeEnum(AnswerType, { message: 'Answer type is required' }),
    showSubmitter: z.boolean(),
    question: z.string().trim().min(1, { message: 'Question is required' }).min(10, { message: 'Question must contain at least 10 character(s)' }).max(100, { message: 'Question must contain at most 100 character(s)' }),
    category: z.nativeEnum(ShapeCategories, { message: 'Category is required' }),
};

type NewQuestionModalProps = {
    isVisible: boolean,
    onDismiss?: () => void,
}

export default function NewQuestionModal({ isVisible, onDismiss }: NewQuestionModalProps) {

    const trpcUtils = trpc.useUtils();
    const { mutate: addQuestion, isLoading: isUpdating } = trpc.shape.addQuestion.useMutation();

    const {
        question,
        setQuestion,
        answerType,
        setAnswerType,
        showSubmitter,
        setShowSubmitter,
        category,
        setCategory,
        validateAll,
        errors,
        updateErrors,
    } = useForm<ValidForm>({ schemas }, {
        showSubmitter: true,
        answerType: AnswerType.percentage,
        question: '',
        category: undefined,
    });

    const onSelectCategory = useCallback((item: string) => {
        const shapeCategory = Object.values(ShapeCategories).find((value) => shapeCategoryToLabel(value) === item);
        if (shapeCategory) {
            setCategory(shapeCategory);
        }
    }, [setCategory]);

    const onSave = useCallback(() => {
        const result = validateAll<ValidForm>();
        if (result.isValid && result.category) {
            addQuestion({
                answerType: result.answerType,
                showSubmitter: result.showSubmitter,
                question: result.question,
                category: result.category,
            }, {
                onSuccess() {
                    trpcUtils.shape.list.invalidate();
                    onDismiss?.();
                },
                onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        }
    }, [addQuestion, onDismiss, trpcUtils.shape.list, validateAll]);

    const onModalHide = useCallback(() => {
        setQuestion('');
        setShowSubmitter(true);
        setAnswerType(AnswerType.percentage);
        setCategory(undefined);
        updateErrors('question', { deleteMessage: true });
        updateErrors('category', { deleteMessage: true });
    }, [setAnswerType, setCategory, setQuestion, setShowSubmitter, updateErrors]);

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={() => onDismiss?.()}
            hideCloseButton
            childrenWrapperStyle={styles.container}
            withoutScrollView
            onModalHide={onModalHide}
            withoutSafeArea
            statusBarTranslucent
        >
            <View style={styles.header}>
                <Text style={styles.headerTitleText}>ADD A QUESTION</Text>
                <TouchableOpacity
                    onPress={() => onDismiss?.()}
                    hitSlop={hitSlop}
                    style={styles.headerCloseBtn}
                >
                    <XIcon size={22} color="#FFFFFF" />
                </TouchableOpacity>
            </View>
            <Spacer height={20} />
            <View style={styles.content}>
                <View style={styles.section}>
                    <Text style={styles.sectionTitleText}>New Question</Text>
                    <ShadowTextInput
                        placeholder="Enter question here"
                        style={styles.questinInput}
                        multiline
                        value={question}
                        onChangeText={setQuestion}
                        isError={errors.question !== undefined}
                        errorMessage={errors.question}
                    />
                </View>
                <Separator />
                <View style={styles.section}>
                    <Text style={styles.sectionTitleText}>Choose response option type</Text>
                    <View style={styles.row}>
                        <CheckBox isActive={answerType === AnswerType.percentage} title="% Percentage" onPress={() => setAnswerType(AnswerType.percentage)} />
                        <Spacer width={32} />
                        <CheckBox isActive={answerType === AnswerType.numeric} title="# Number" onPress={() => setAnswerType(AnswerType.numeric)} />
                    </View>
                </View>
                <Separator />
                <View style={styles.section}>
                    <Text style={styles.sectionTitleText}>Question Category</Text>
                </View>
                <DropdownPicker
                    title=""
                    placeholder="Select a question category"
                    value={category ? shapeCategoryToLabel(category) : undefined}
                    items={Object.values(ShapeCategories).map(shapeCategoryToLabel)}
                    onSelectItem={onSelectCategory}
                    style={category !== undefined && styles.dropdownPicker}
                    dropdownTextStyle={category !== undefined && styles.dropdownPickerLabel}
                    modalStyle={styles.dropdownModal}
                    isError={errors.category !== undefined}
                    errorMessage={errors.category}
                />
                <Separator />
                <View style={styles.section}>
                    <Text style={styles.sectionTitleText}>Questions are anonymous by default. If featured in Sapphic Savvy, should we include 'submitted by your [username]’?</Text>
                    <View style={styles.row}>
                        <CheckBox isActive={showSubmitter} title="Yes" onPress={() => setShowSubmitter(true)} />
                        <Spacer width={32} />
                        <CheckBox isActive={!showSubmitter} title="No" onPress={() => setShowSubmitter(false)} />
                    </View>
                </View>
                <Separator />
                <Spacer />
                <GradientButton
                    title="SUBMIT"
                    onPress={onSave}
                    loading={isUpdating}
                    disabled={isUpdating}
                />
                <Spacer height={40} />
            </View>
        </MB_Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#EFEFF3',
        marginVertical: 0,
        width: '100%',
        height: '100%',
        paddingHorizontal: 0,
        paddingBottom: 0,
        marginBottom: 1,
        paddingTop: 0,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
        paddingBottom: 20,
        paddingLeft: 30,
        paddingRight: 20,
        paddingTop: 20 + getTopInset(),
        backgroundColor: '#F35E2E',
    },
    headerTitleText: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        fontSize: 25,
        color: '#FFFFFF',
    }]),
    headerCloseBtn: {
        position: 'absolute',
        right: 20,
        bottom: 25,
    },
    content: {
        flex: 1,
        alignSelf: 'stretch',
        paddingHorizontal: 20,
    },
    section: {
        alignSelf: 'stretch',
    },
    sectionTitleText: mbTextStyles([textStyles.normalText, {
        fontWeight: '600',
        fontFamily: FONT_STYLES.PlusJakartaSans_600,
        textAlign: 'left',
        color: mbApplyTransparency('#000000', 0.55),
        marginBottom: 18,
    }]),
    questinInput: {
        height: 132,
    },
    separator: {
        marginVertical: 20,
    },
    separatorTopLine: {
        height: 1,
        backgroundColor: mbApplyTransparency('#FFFFFF', 0.50),
        marginHorizontal: -20,
        ...mbShadow({
            offsetHeight: -1,
            offsetWidth: 0,
            color: '#FFFFFF',
            opacity: 0.25,
            elevation: 1,
            radius: 10,
        }),
    },
    separatorBottomLine: {
        height: 1,
        backgroundColor: mbApplyTransparency('#8E9396', Platform.OS === 'ios' ? 1 : 0.50),
        marginHorizontal: -20,
        ...mbShadow({
            offsetHeight: 1,
            offsetWidth: 0,
            color: '#000000',
            opacity: 0.05,
            elevation: 1,
            radius: 10,
        }),
    },
    row: {
        flexDirection: 'row',
    },
    dropdownPicker: {
        borderWidth: 1,
        borderColor: '#F35E2E',
        borderRadius: 8,
    },
    dropdownPickerLabel: mbTextStyles([textStyles.normalText, {
        color: '#F35E2E',
        textAlign: 'left',
    }]),
    dropdownModal: {
        height: 200,
    },

    // checkBox
    checkBoxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    checkBox: {
        width: 26,
        height: 26,
        borderRadius: 360,
        borderWidth: 2,
        borderColor: '#F35E2E',
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkBoxActive: {
        width: 13,
        height: 13,
        borderRadius: 360,
        backgroundColor: '#F35E2E',
    },
    checkBoxText: mbTextStyles([textStyles.normalText, {
        color: '#000000',
        fontFamily: FONT_STYLES.PlusJakartaSans_600,
        fontWeight: '600',
        textAlign: 'left',
        marginLeft: 12,
    }]),
});
