import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { NativeScrollEvent, NativeSyntheticEvent, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { trpc } from '../../../apiCalls/trpcClient';
import OveviewScreen from '../../helperComponents/OveviewScreen';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../constants/textStyles';
import CoinsIcon from '../../../resources/svgComponents/CoinsIcon';
import { Spacer } from '../../helperComponents/Spacer';
import { StandingWithNumberIcon } from '../../../resources/svgComponents/StandingWithNumberIcon';
import { UIActivityIndicator } from '@mightybyte/rnw.components.activity-indicators';
import BadgeBackground from '../../../resources/svgComponents/Badges/BadgeBackground';
import { envs } from '../../../../env';
import StreakIcon from '../../../resources/svgComponents/StreakIcon';
import { BadgeTypes } from '@sapphicsavvy/business';
import { badgeToString } from '../../../utils/shared';
import GlowingStarIcon from '../../../resources/svgComponents/Badges/GlowingStarIcon';
import SpiralCalendarIcon from '../../../resources/svgComponents/Badges/SpiralCalendarIcon';
import LoudsSpeakerIcon from '../../../resources/svgComponents/Badges/LoudsSpeakerIcon';
import PaperIcon from '../../../resources/svgComponents/Badges/PaperIcon';
import BullsEyeIcon from '../../../resources/svgComponents/Badges/BullsEyeIcon';
import MilitaryModalIcon from '../../../resources/svgComponents/Badges/MilitaryModalIcon';

type CardProps = {
    title: string,
    value?: string | number,
    icon: React.ReactChild
}

function Card({ title, value, icon }: CardProps) {
    return (
        <View style={styles.card}>
            <Text style={styles.cardTitleText}>{title}</Text>
            <View style={styles.cardContent}>
                {icon}
                <View style={styles.cardValueContainer}>
                    <Text adjustsFontSizeToFit numberOfLines={1} style={styles.cardValueText}>{value}</Text>
                </View>
            </View>
        </View>
    );
}

type BadgeCardProps = {
    backgroundColor?: string,
    withStars?: boolean,
    badgeType: BadgeTypes,
    height?: number,
    numberOfBadges?: number,
}

function BadgeCard({ backgroundColor, withStars, badgeType, height = 130, numberOfBadges }: BadgeCardProps) {
    const icons = {
        [BadgeTypes.idealAnswer]: <GlowingStarIcon />,
        [BadgeTypes.playStreaker]: <SpiralCalendarIcon />,
        [BadgeTypes.topReferrer]: <LoudsSpeakerIcon />,
        [BadgeTypes.topQuestionSubmitter]: <PaperIcon />,
        [BadgeTypes.top100Scorer]: <BullsEyeIcon />,
        [BadgeTypes.prizeWinner]: <MilitaryModalIcon />,
    };
    return (
        <View style={[styles.badgeCard, { height }]}>
            <View style={[styles.badgeCard, { height, overflow: 'hidden' }]}>
                <BadgeBackground color={backgroundColor} withStars={withStars} height={height} />
                <View style={styles.badgeCardContent}>
                    <View style={styles.badgeIcon}>
                        {icons[badgeType]}
                    </View>
                    <Text style={styles.badgeCardTitle}>{badgeToString(badgeType)}</Text>
                </View>
                {(!numberOfBadges || numberOfBadges <= 0) &&
                    <View style={styles.badgeCardDisabled}>
                        <Text style={styles.badgeCardDisabledText}>Locked</Text>
                    </View>
                }
            </View>
            {(numberOfBadges !== undefined && numberOfBadges > 0) &&
                <View style={styles.badgeCardNumber}>
                    <Text style={styles.badgeCardNumberText}>{numberOfBadges}</Text>
                </View>
            }
        </View>
    );
}


export default function Stats() {
    const { data, isLoading, isFetching } = trpc.user.getStats.useQuery();
    const scrollViewRef = useRef<ScrollView | null>(null);
    const [scrollOffsetY, setScrollOffsetY] = useState(0);
    const calcStyles = useMemo(() => ({
        sectionTop: {
            zIndex: scrollOffsetY <= 5 ? 1 : -1,
        },
    }), [scrollOffsetY]);

    const onScroll = useCallback((e: NativeSyntheticEvent<NativeScrollEvent>) => setScrollOffsetY(e.nativeEvent.contentOffset.y), []);
    const onBackdrop = useCallback(() => scrollViewRef.current?.scrollTo({ y: 0, animated: true }), []);
    return (
        <OveviewScreen
            showBackBtn
            topSectionStyle={[styles.topSection, calcStyles.sectionTop]}
            topSectionChildren={
                (isLoading || (envs.FLAVOR === 'dev' ? isFetching : false)) ?
                    <UIActivityIndicator color="#FFFFFF" />
                    :
                    <Fragment>
                        <Text style={styles.titleText}>STATS</Text>
                        <View style={styles.cards}>
                            <Card
                                title="Points Bet"
                                value={data?.pointsBet}
                                icon={<CoinsIcon size={31} />}
                            />
                            <Spacer width={11} />
                            <Card
                                title="Play Streak"
                                value={data?.currentStreak}
                                icon={<StreakIcon size={31} />}
                            />
                        </View>
                        <Spacer height={11} />
                        <View style={styles.cards}>
                            <Card
                                title="Average Rank"
                                value={data?.averageRank}
                                icon={<StandingWithNumberIcon size={39} />}
                            />
                            <Spacer width={15} />
                            <Card
                                title="This Set’s Prize"
                                value={data?.setPrize}
                                icon={<Text style={styles.dollarIcon}>$</Text>}
                            />
                        </View>
                        <Text style={styles.chartTitleText}>PERFORMANCE AND <Text style={{ color: mbApplyTransparency('#FCAF41', 0.95) }}>POINTS BET</Text></Text>
                        <View style={{ width: 309, height: 168, borderWidth: 1, borderColor: '#FFFFFF', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ color: '#FFFFFF' }}>coming soon</Text>
                        </View>
                    </Fragment>
            }
            gardientColors={['#6A2C8C', '#6A2C8C']}
            bottomSectionStyle={styles.bottomSection}
        >
            <ScrollView
                ref={scrollViewRef}
                style={styles.bottomSectionContainer}
                contentContainerStyle={styles.bottomSectionContainerContent}
                showsVerticalScrollIndicator={false}
                onScroll={onScroll}
                scrollEventThrottle={10}
            >
                <TouchableOpacity
                    style={styles.backdrop}
                    onPress={onBackdrop}
                    activeOpacity={1}
                />
                <View style={styles.badges}>
                    <View style={styles.badgesRow}>
                        <BadgeCard badgeType={BadgeTypes.idealAnswer} numberOfBadges={data?.badges.idealAnswer} />
                        <Spacer width={12} />
                        <BadgeCard badgeType={BadgeTypes.playStreaker} numberOfBadges={data?.badges.playStreaker} />
                        <Spacer width={12} />
                        <BadgeCard badgeType={BadgeTypes.topReferrer} numberOfBadges={data?.badges.topReferrer} />
                    </View>
                    <Spacer height={10} />
                    <View style={styles.badgesRow}>
                        <BadgeCard backgroundColor="#A42FC1" withStars={false} badgeType={BadgeTypes.topQuestionSubmitter} height={138} numberOfBadges={data?.badges.topQuestionSubmitter} />
                        <Spacer width={12} />
                        <BadgeCard backgroundColor="#A42FC1" withStars={false} badgeType={BadgeTypes.top100Scorer} height={138} numberOfBadges={data?.badges.top100Scorer} />
                        <Spacer width={12} />
                        <BadgeCard backgroundColor="#A42FC1" withStars={false} badgeType={BadgeTypes.prizeWinner} height={138} numberOfBadges={data?.badges.prizeWinner} />
                    </View>
                    <View style={styles.stickyBackground} />
                </View>
            </ScrollView>
        </OveviewScreen>
    );
}

const styles = StyleSheet.create({
    topSection: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20,
        height: '70%',
        zIndex: 1,
    },
    titleText: mbTextStyles([textStyles.largestText, {
        fontSize: 45,
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#FFFFFF',
        fontWeight: '400',
        marginBottom: 18,
    }]),
    cards: {
        flexDirection: 'row',
        alignSelf: 'stretch',
    },
    dollarIcon: mbTextStyles([textStyles.largestText, {
        fontSize: 35,
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#F7D345',
        fontWeight: '400',
    }]),
    chartTitleText: mbTextStyles([textStyles.largestText, {
        fontSize: 18,
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#FFFFFF',
        fontWeight: '400',
        marginVertical: 18,
    }]),
    bottomSection: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        paddingTop: 0,
        height: '100%',
        position: 'absolute',
        width: '100%',
        backgroundColor: 'transparent',
    },
    bottomSectionContainer: {
        width: '100%',
        height: '100%',
    },
    bottomSectionContainerContent: {
        paddingTop: '150%',
    },
    backdrop: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        position: 'absolute',
    },
    badges: {
        borderTopLeftRadius: 36,
        borderTopRightRadius: 36,
        backgroundColor: '#EEEEF2',
        paddingVertical: 40,
        flexGrow: 1,
        ...mbShadow({
            offsetWidth: -5,
            offsetHeight: 0,
            color: '#000000',
            opacity: 0.15,
            radius: 10,
            elevation: 2,
        }),
    },
    badgesRow: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    stickyBackground: {
        position: 'absolute',
        bottom: -1000,
        left: 0,
        right: 0,
        height: 1000,
        backgroundColor: '#EEEEF2',
    },

    // Card
    card: {
        flexGrow: 1,
        paddingHorizontal: 15,
        paddingVertical: 10,
        backgroundColor: '#5B237A',
        borderRadius: 10,
    },
    cardContent: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        alignItems: 'center',
        marginTop: 10,
    },
    cardTitleText: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#FFFFFF', 0.85),
        textAlign: 'left',
    }]),
    cardValueContainer: {
        flex: 1,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    cardValueText: mbTextStyles([textStyles.largerText, {
        fontSize: 32,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#FFFFFF',
        marginLeft: 8,
        textAlign: 'left',
    }]),

    // Badge Card
    badgeCard: {
        borderRadius: 10,
        // overflow: 'hidden',
        width: 101,
        height: 130,
    },
    badgeCardDisabled: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: 10,
        backgroundColor: mbApplyTransparency('#000000', 0.5),
        alignItems: 'center',
        justifyContent: 'center',
    },
    badgeCardDisabledText: mbTextStyles([textStyles.largerText, {
        fontSize: 20,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#FFFFFF',
        marginTop: 8,
        paddingHorizontal: 5,
        textAlign: 'center',
        transform: [{ rotate: '30deg' }],
    }]),
    badgeCardContent: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    badgeIcon: {
        width: 73,
        height: 73,
        borderRadius: 360,
        backgroundColor: '#85249D',
        marginLeft: 14,
        marginTop: 9,
        alignItems: 'center',
        justifyContent: 'center',
    },
    badgeCardTitle: mbTextStyles([textStyles.largerText, {
        fontSize: 14,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#FFFFFF',
        marginTop: 8,
        paddingHorizontal: 5,
    }]),
    badgeCardNumber: {
        borderRadius: 360,
        backgroundColor: '#EE3823',
        position: 'absolute',
        height: 20,
        width: 20,
        right: -5,
        top: -5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    badgeCardNumberText: mbTextStyles(textStyles.smallerText),
});
