export {
    tellSign,
    getAgeGroup,
    ageGroupToDisplayLabel,
    getGroupedTags,
    fixedPointsAmount,
    answerMargins,
    shapeCategoryToLabel,
    chatRoomNameToLabel,
} from './utils';
