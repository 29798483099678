export enum NotificationTypes {
    RESULTS_READY = 'RESULTS_READY',
    NEW_QUARTER = 'NEW_QUARTER',
    PLAY_QUARTER_FIRST_REMINDER = 'PLAY_QUARTER_FIRST_REMINDER',
    PLAY_QUARTER_SECOND_REMINDER = 'PLAY_QUARTER_SECOND_REMINDER',
    CUSTOM = 'CUSTOM',
}

export enum NotificationTargets {
    all = 'all',
    inStudy = 'inStudy',
    notPlayed = 'notPlayed',
}
