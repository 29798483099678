import React from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from './Shared/styles';
import { Header } from './Shared/Header';
import BookIcon from '../../../resources/svgComponents/BookIcon';
import { ShadowView } from '../../helperComponents/ShadowView';
import { Linking, StyleSheet, Text, View } from 'react-native';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import SupportIcon from '../../../resources/svgComponents/SupportIcon';
import ClosedBookIcon from '../../../resources/svgComponents/ClosedBookIcon';
import { Spacer } from '../../helperComponents/Spacer';
import { textStyles } from '../../../constants/textStyles';
import { envs } from '../../../../env';

type CardProps = {
    icon: React.ReactNode;
    color: string;
    title: string;
    text: string;
    onPress?: () => void;
}

function Card({ title, text, icon, color, onPress }: CardProps) {
    return (
        <ShadowView style={styles.card} onPress={onPress}>
            <ShadowView style={[styles.cardIcon, { backgroundColor: color }]}>
                {icon}
            </ShadowView>
            <Text style={styles.cardTitleText}>{title}</Text>
            <Text style={styles.cardText}>{text}</Text>
        </ShadowView>
    );
}

const Read = () => {

    return (
        <ComponentWrapper
            containerStyle={sharedStyles.container}
        >
            <Header title="READ" icon={<BookIcon size={40} />} />
            <View>
                <View style={styles.headerTopLine} />
                <View style={styles.headerBottomLine} />
            </View>
            <View style={styles.row}>
                <Card
                    title="Support"
                    text="Information and resources to support sapphic well-being"
                    color="#0295CD"
                    icon={<SupportIcon color="#FFFFFF" size={66} />}
                    onPress={() => Linking.openURL(envs.LINKS.support)}
                />
                <Spacer width={15} />
                <Card
                    title="Synthesis"
                    text="More data and commentary in case you just can’t get enough"
                    color="#F35E2E"
                    icon={<ClosedBookIcon />}
                    onPress={() => Linking.openURL(envs.LINKS.synthesis)}
                />
            </View>
        </ComponentWrapper>
    );

};

export { Read };

const styles = StyleSheet.create({
    headerTopLine: {
        height: 1,
        backgroundColor: mbApplyTransparency('#FFFFFF', 0.50),
        marginHorizontal: -20,
        ...mbShadow({
            offsetHeight: -1,
            offsetWidth: 0,
            color: '#FFFFFF',
            opacity: 0.25,
            elevation: 1,
            radius: 10,
        }),
    },
    headerBottomLine: {
        height: 1,
        backgroundColor: mbApplyTransparency('#8E9396', 1),
        marginHorizontal: -20,
        ...mbShadow({
            offsetHeight: 1,
            offsetWidth: 0,
            color: '#000000',
            opacity: 0.05,
            elevation: 1,
            radius: 10,
        }),
    },
    row: {
        marginTop: 60,
        flexDirection: 'row',
        paddingHorizontal: 6,
    },

    // Card
    card: {
        padding: 10,
        flexGrow: 1,
        flexShrink: 1,
    },
    cardIcon: {
        height: 109,
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardTitleText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        color: '#292E30',
        fontWeight: '600',
        textAlign: 'left',
        marginTop: 14,
    }]),
    cardText: mbTextStyles([textStyles.smallText, {
        color: '#677278',
        fontWeight: '400',
        marginTop: 5,
        textAlign: 'left',
        paddingRight: 20,
    }]),
});
