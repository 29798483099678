import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import OveviewScreen from '../../helperComponents/OveviewScreen';
import { FlatList, ListRenderItemInfo, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { MB_TabView, MB_TabViewRef } from '@mightybyte/rnw.components.tab-view';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../constants/textStyles';
import HealthyHeartIcon from '../../../resources/svgComponents/HealthyHeartIcon';
import { trpc } from '../../../apiCalls/trpcClient';
import { useMBInfiniteFetch } from '@mightybyte/rnw.utils.util-hooks';
import { ListResultShapeDataForUserClient, ShapeCategories, shapeCategoryToLabel, ShapeSortOptions } from '@sapphicsavvy/business';
import { Spacer } from '../../helperComponents/Spacer';
import { UIActivityIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { ShadowView } from '../../helperComponents/ShadowView';
import { InnerShadow } from '../../helperComponents/InnerShadow';
import { GradientButton } from '../../helperComponents/gradients/GradientButton';
import DonateBoxIcon from '../../../resources/svgComponents/DonateBoxIcon';
import PlusSignIcon from '../../../resources/svgComponents/Quarter/PlusSignIcon';
import NewQuestionModal from './modals/NewQuestionModal';
import FilterModal from './modals/FilterModal';
import SlidersIcon from '../../../resources/svgComponents/SlidersIcon';
import { mutateUpVoteQuestion } from '../../../utils/mutations';

function useFilter() {
    const [isVisible, setIsVisible] = useState(false);
    const [filter, setFilter] = useState<ShapeCategories[]>();

    return {
        filter,
        isVisible,
        show: useCallback(() => setIsVisible(true), []),
        hide: useCallback((updatedFilter?: ShapeCategories[]) => {
            setFilter(prev => updatedFilter ?? prev);
            setIsVisible(false);
        }, []),
    };
}

function useUpVoteQuestion(options: { sortBy: ShapeSortOptions, filter?: ShapeCategories[] }) {
    const trpcUtils = trpc.useUtils();
    return trpc
        .shape
        .upvoteQuestion
        .useMutation(mutateUpVoteQuestion(trpcUtils, [{
            sortBy: ShapeSortOptions.mostVoted,
            filter: options.filter ? { category: options.filter } : undefined,
        }, {
            sortBy: ShapeSortOptions.newest,
            filter: options.filter ? { category: options.filter } : undefined,
        }]));
}

function useInfiniteListOfQuestions(options: { sortBy: ShapeSortOptions, filter?: ShapeCategories[] }) {
    return trpc
        .shape
        .list
        .useInfiniteQuery({
            sortBy: options.sortBy,
            filter: options.filter ? { category: options.filter } : undefined,
        }, {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
            cacheTime: 0,
        });
}

function ShapeView({ sortBy, filter, onPressFilter }: { sortBy: ShapeSortOptions, filter?: ShapeCategories[], onPressFilter?: () => void }) {

    const listOfShaps = useInfiniteListOfQuestions({ sortBy, filter });
    const { data, loadMore, isLoading, isFetching } = useMBInfiniteFetch(listOfShaps, { prefillWhileLoading: false });
    const { mutate: upvoteQuestion } = useUpVoteQuestion({ sortBy, filter });

    const keyExtractor = useCallback((item: ListResultShapeDataForUserClient | string) => typeof item === 'string' ? item : item.id, []);
    const itemSeparator = useCallback(() => <Spacer height={20} />, []);

    const onUpVoteQuestion = useCallback((questionId: string) => {
        upvoteQuestion({ questionId });
    }, [upvoteQuestion]);

    const renderItem = useCallback(({ item }: ListRenderItemInfo<ListResultShapeDataForUserClient | string>) => {
        return (
            <ShadowView style={styles.item}>
                <Text style={styles.itemTitleText}>{typeof item === 'string' ? 'Loading' : item.question}</Text>
                <View style={styles.itemInfo}>
                    <ShadowView style={styles.itemCategory}>
                        <Text style={styles.itemCategoryText}>{typeof item === 'string' ? 'Loading' : shapeCategoryToLabel(item.category)}</Text>
                    </ShadowView>
                    <InnerShadow style={styles.vote}>
                        <Text style={styles.voteText}>{typeof item === 'string' ? 'Loading' : item.votesTotal} Votes</Text>
                    </InnerShadow>
                </View>
                <View style={styles.itemFooter}>
                    {typeof item !== 'string' && (item.hasVoted ?
                        <ShadowView style={styles.itemFooterView}>
                            <Text style={styles.itemFooterViewText}>You’ve voted for this question!</Text>
                        </ShadowView>
                        :
                        <GradientButton
                            style={styles.itemFooterBtn}
                            containerStyle={styles.itemFooterBtnContainer}
                            colors={['#FFFFFF', '#FFFFFF']}
                            leftElement={<DonateBoxIcon />}
                            title="Cast your vote"
                            textStyle={styles.itemFooterBtnTitleText}
                            onPress={() => onUpVoteQuestion(item.id)}
                        />)
                    }
                </View>
            </ShadowView>
        );
    }, [onUpVoteQuestion]);

    const ListFooterComponent = useCallback(() => {
        return (
            <View style={styles.shapeListFooter}>
                <UIActivityIndicator size={30} color="#F35E2E" />
            </View>
        );
    }, []);

    const ListHeaderComponent = useCallback(() => {
        return (
            <Fragment>
                <Text style={styles.shapeTitleText}>{sortBy === ShapeSortOptions.mostVoted ? 'Top' : 'New'} Questions ({data.length})</Text>
                <ShadowView
                    style={styles.filterBtn}
                    onPress={onPressFilter}
                >
                    <SlidersIcon />
                    <Text style={styles.filterText}>Filter</Text>
                    {filter && filter.length > 0 &&
                        <View style={styles.filterNote}>
                            <Text style={styles.filterNoteText}>{filter.length}</Text>
                        </View>
                    }
                </ShadowView>
            </Fragment>
        );
    }, [data.length, filter, onPressFilter, sortBy]);

    const ListEmptyComponent = useCallback(() => {
        if (isLoading) {
            return <ListFooterComponent />;
        }
        return (
            <View style={styles.shapeListEmpty}>
                <Text style={styles.shapeListEmptyText}>No Questions available.</Text>
            </View>
        );
    }, [ListFooterComponent, isLoading]);

    return (
        <View style={styles.shape}>
            <FlatList
                data={data as (ListResultShapeDataForUserClient | string)[]}
                renderItem={renderItem}
                style={styles.shapeQuestionsList}
                contentContainerStyle={styles.shapeQuestionsListContent}
                onEndReached={loadMore}
                onEndReachedThreshold={0.3}
                keyExtractor={keyExtractor}
                ItemSeparatorComponent={itemSeparator}
                ListFooterComponent={(isFetching && !isLoading && data.length >= 10) ? ListFooterComponent : null}
                ListEmptyComponent={ListEmptyComponent}
                ListHeaderComponent={ListHeaderComponent}
                ListHeaderComponentStyle={styles.shapeListHeaderContainer}
                stickyHeaderIndices={[0]}
            />
        </View>
    );
}

export default function Shape() {
    const titles = useRef(['Top', 'New']).current;
    const tabViewRef = useRef<MB_TabViewRef>(null);

    const [currentTab, setCurrentTab] = useState<0 | 1>(0);
    const [isNewQuestionModalVisible, setIsNewQuestionModalVisible] = useState(false);
    const { isVisible, filter, show: showFilter, hide: hideFilter } = useFilter();

    const changeTab = useCallback(() => setCurrentTab(prevTab => {
        const newTab = prevTab === 0 ? 1 : 0;
        tabViewRef.current?.navigateToTab(newTab);
        return newTab;
    }), []);

    const screens = useMemo(() => [
        () => (<ShapeView filter={filter} onPressFilter={showFilter} sortBy={ShapeSortOptions.mostVoted} />),
        () => (<ShapeView filter={filter} onPressFilter={showFilter} sortBy={ShapeSortOptions.newest} />),
    ], [filter, showFilter]);

    return (
        <OveviewScreen
            showBackBtn
            topSectionStyle={styles.topSection}
            topSectionChildren={
                <Fragment>
                    <View style={styles.title}>
                        <HealthyHeartIcon />
                        <Text style={styles.titleText}>SHAPE</Text>
                    </View>
                    <Text style={styles.subTitleText}>Vote on questions to be in future quarters and submit your own</Text>
                    <View style={styles.tabs}>
                        <TouchableOpacity
                            style={[styles.tabsBtn, currentTab === 0 && styles.tabsBtnActive]}
                            onPress={changeTab}
                            activeOpacity={0.5}
                        >
                            <Text style={[styles.tabsBtnText, currentTab === 0 && styles.tabsBtnTextActive]}>{titles[0]}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[styles.tabsBtn, currentTab === 1 && styles.tabsBtnActive]}
                            onPress={changeTab}
                            activeOpacity={0.5}
                        >
                            <Text style={[styles.tabsBtnText, currentTab === 1 && styles.tabsBtnTextActive]}>{titles[1]}</Text>
                        </TouchableOpacity>
                    </View>
                </Fragment>
            }
            bottomSectionStyle={styles.bottomSection}
        >
            <MB_TabView
                MB_Ref={tabViewRef}
                titles={titles}
                screens={screens}
                tabStyle={styles.tab}
                swipeEnabled={false}
            />
            <GradientButton
                containerStyle={styles.addQuestionBtnContainer}
                style={styles.addQuestionBtn}
                title=""
                leftElement={<PlusSignIcon color="#FFFFFF" size={30} />}
                onPress={() => setIsNewQuestionModalVisible(true)}
            />
            <NewQuestionModal isVisible={isNewQuestionModalVisible} onDismiss={() => setIsNewQuestionModalVisible(false)} />
            <FilterModal isVisible={isVisible} selectedCategories={filter} onDismiss={hideFilter} />
        </OveviewScreen>
    );
}

const styles = StyleSheet.create({
    topSection: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20,
        height: '35%',
    },
    bottomSection: {
        height: '65%',
        paddingTop: 0,
    },
    title: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    titleText: mbTextStyles([textStyles.largestText, {
        fontSize: 45,
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#FFFFFF',
        fontWeight: '400',
        marginLeft: 9,
    }]),
    subTitleText: mbTextStyles([textStyles.largeText, {
        color: '#FFFFFF',
        fontSize: 18,
        fontWeight: '700',
        marginBottom: 20,
    }]),
    tab: {
        display: 'none',
    },
    tabs: {
        height: 54,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderWidth: 1,
        borderRadius: 10,
        paddingHorizontal: 7,
        borderColor: mbApplyTransparency('#FFFFFF', 0.55),
        ...mbShadow({
            offsetHeight: 2,
            offsetWidth: 2,
            color: '#565656',
            opacity: 0.50,
            elevation: 20,
            radius: 20,
        }),
    },
    tabsBtn: {
        height: 40,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    tabsBtnActive: {
        backgroundColor: '#F35E2E',
    },
    tabsBtnText: mbTextStyles([textStyles.smallText, {
        textAlign: 'left',
        color: mbApplyTransparency('#6D6E71', 0.95),
    }]),
    tabsBtnTextActive: {
        color: '#F8F8F8',
    },

    addQuestionBtnContainer: {
        position: 'absolute',
        bottom: 15,
        right: 12,
        borderRadius: 360,
    },
    addQuestionBtn: {
        height: 72,
        width: 72,
    },

    // shape
    shape: {
        flex: 1,
        borderTopLeftRadius: 36,
        borderTopRightRadius: 36,
        overflow: 'hidden',
    },
    shapeHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
        marginVertical: 30,
    },
    shapeTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 18,
        fontWeight: '600',
        textAlign: 'left',
        color: '#292E30',
    }]),
    filterBtn: {
        flexDirection: 'row',
        alignItems: 'center',
        width: 115,
        height: 43,
        justifyContent: 'space-between',
        paddingHorizontal: 18,
        borderWidth: 1,
        borderColor: '#F35E2E',
    },
    filterText: mbTextStyles([textStyles.largeText, {
        fontSize: 18,
        fontWeight: '600',
        fontFamily: FONT_STYLES.PlusJakartaSans_600,
        textAlign: 'left',
        color: '#F35E2E',
    }]),
    filterNote: {
        borderRadius: 360,
        backgroundColor: '#E50914',
        position: 'absolute',
        height: 15,
        width: 15,
        right: -5,
        top: -5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    filterNoteText: mbTextStyles(textStyles.tinyText),
    shapeQuestionsList: {
        flex: 1,
        paddingHorizontal: 20,
    },
    shapeQuestionsListContent: {
        paddingBottom: 40,
    },
    shapeListHeaderContainer: {
        paddingVertical: 20,
        marginBottom: 10,
        backgroundColor: '#EEEEF2',
        marginHorizontal: -20,
        paddingHorizontal: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    shapeListEmpty: {
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    shapeListEmptyText: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#000000', 0.55),
    }]),
    shapeListFooter: {
        height: 60,
    },
    item: {
        paddingTop: 20,
    },
    itemTitleText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '600',
        color: '#292E30',
        marginHorizontal: 35,
        textAlign: 'left',
    }]),
    itemInfo: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginVertical: 15,
        marginHorizontal: 20,
    },
    itemCategory: {
        padding: 8,
    },
    itemCategoryText: mbTextStyles([textStyles.normalText, {
        fontWeight: '500',
        color: mbApplyTransparency('#6D6E71', 0.95),
        letterSpacing: -1,
    }]),
    vote: {
        paddingVertical: 8,
        paddingHorizontal: 16,
    },
    voteText: mbTextStyles([textStyles.normalText, {
        fontWeight: '700',
        color: '#F35E2E',
    }]),
    itemFooter: {
        backgroundColor: '#FFF0EF',
        paddingHorizontal: 16,
        paddingVertical: 20,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
    },
    itemFooterView: {
        backgroundColor: '#FEF6F5',
        height: 50,
        justifyContent: 'center',
    },
    itemFooterViewText: mbTextStyles([textStyles.normalText, {
        fontWeight: '700',
        color: '#F35E2E',
    }]),
    itemFooterBtnContainer: {
        overflow: 'hidden',
        borderWidth: 2,
        borderColor: '#F35E2E',
    },
    itemFooterBtn: {
        height: 50,
    },
    itemFooterBtnTitleText: mbTextStyles([textStyles.normalText, {
        fontWeight: '700',
        color: '#F35E2E',
    }]),
});
