import React, { useCallback } from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { trpc } from '../../../apiCalls/trpcClient';
import { ShadowView } from '../../helperComponents/ShadowView';
import { Image, Platform, StyleSheet, Text, View } from 'react-native';
import { getTopInset } from '../../../utils/shared';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../constants/textStyles';
import { Spacer } from '../../helperComponents/Spacer';
import { imageUtils } from '../../../utils/imageUtils';
import CountDown from '../../helperComponents/CountDown';
import { GradientButton } from '../../helperComponents/gradients/GradientButton';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../../navigations/RootNavigator';
import { QuarterDataToPlay } from '@sapphicsavvy/business';
import Fontisto from 'react-native-vector-icons/Fontisto';
import Card from './Results/components/Card';
import HealthyHeartIcon from '../../../resources/svgComponents/HealthyHeartIcon';
import IncognitoIcon from '../../../resources/svgComponents/IncognitoIcon';


const Home = () => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const { data: haveToPlay, isLoading: isHaveToPlayLoading } = trpc.quarter.haveToPlay.useQuery();
    const { data: quarterData, isLoading: isQuarterLoading } = trpc.quarter.getQuarterDataToPlay.useQuery({
        quarterId: haveToPlay?.quarterId || '',
    }, { enabled: haveToPlay !== undefined });

    const isLocked = haveToPlay ? !haveToPlay.isPlayed : true;

    const onButtonPress = useCallback(() => {
        if (quarterData && !haveToPlay?.isPlayed) {
            const questionIndex = quarterData.questions.findIndex(question => question.id === haveToPlay?.questionId);
            navigation.navigate('Quarter', {
                quarterData: quarterData as QuarterDataToPlay,
                questionIndex,
                step: 'Guess',
                answer: {
                    questionId: quarterData.questions[questionIndex].id,
                    answerType: quarterData.questions[questionIndex].answerType,
                },
                answers: [],
            });
        } else {
            navigation.navigate('Stats');
        }
    }, [haveToPlay, navigation, quarterData]);

    return (
        <ComponentWrapper
            containerStyle={[styles.container]}
        >
            <ShadowView style={styles.quarterCard}>
                <ShadowView style={styles.quarterCardInner}>
                    <Image
                        style={styles.logo}
                        source={imageUtils.screens.logoHomeScreen}
                        resizeMode="cover"
                    />
                    <Text style={styles.quarterCardTitleText}>QUARTER {quarterData?.quarterNumber}</Text>
                    <Text style={styles.quarterCardText}>CLOSES IN</Text>
                    <Spacer height={10} />
                    <View>
                        <View style={styles.colors}>
                            <Spacer height={20} color="#0495CD" />
                            <Spacer height={20} color="#6A2C8C" />
                            <Spacer height={20} color="#EE3823" />
                            <Spacer height={20} color="#FCAF41" />
                        </View>
                        {(isHaveToPlayLoading || isQuarterLoading) ?
                            <View style={styles.loading}>
                                <Text style={styles.loadingText}>Loading</Text>
                            </View>
                            :
                            <CountDown
                                labelsStyle={styles.countdownLabels}
                                style={styles.countdown}
                                endTime={quarterData?.endTimestamp ?? 0}
                            />
                        }
                    </View>
                </ShadowView>
            </ShadowView>
            <GradientButton
                title="START"
                containerStyle={styles.startBtn}
                disabled={isHaveToPlayLoading || isQuarterLoading || haveToPlay?.isPlayed}
                onPress={onButtonPress}
            />
            <View>
                <View style={styles.separatorTopLine} />
                <View style={styles.separatorBottomLine} />
            </View>
            <View style={styles.bottomSection}>
                <View style={styles.bottomSectionHeader}>
                    <Text style={styles.bottomSectionTitleText}>Discover More</Text>
                    {isLocked &&
                        <View style={styles.bottomSectionNote}>
                            <Fontisto name="locked" color={mbApplyTransparency('#292E30', 0.55)} size={14} />
                            <Text style={styles.bottomSectionNoteText}>Play Quarter to unlock</Text>
                        </View>
                    }
                </View>
                <Card
                    icon={<HealthyHeartIcon />}
                    color={['#EE3823', '#FCAF41']}
                    title="Shape"
                    text="Submit & vote on future questions"
                    isLocked={isLocked}
                    onPress={() => navigation.navigate('Shape')}
                />
                <Spacer height={15} />
                <Card
                    icon={<IncognitoIcon />}
                    color={['#EE3823', '#FCAF41']}
                    title="Sleuth"
                    text="Tap the lies about this quarter’s featured users"
                    isLocked={isLocked}
                />
            </View>
        </ComponentWrapper>
    );

};

export { Home };

const styles = StyleSheet.create({
    container: {
        paddingTop: getTopInset() + 60,
    },
    logo: {
        position: 'absolute',
        width: 85,
        height: 97,
        alignSelf: 'center',
        top: -60,
    },
    quarterCard: {
        padding: 15,
    },
    quarterCardInner: {
        paddingTop: 40,
        paddingBottom: 25,
    },
    quarterCardTitleText: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'center',
        fontSize: 38,
        color: '#000000',
    }]),
    quarterCardText: mbTextStyles([{
        fontFamily: FONT_STYLES.Staatliches_400,
        textAlign: 'center',
        fontSize: 18,
        color: '#EE3823',
        marginBottom: 5,
    }]),
    colors: {
        position: 'absolute',
        top: -10,
        bottom: 0,
        left: 0,
        right: 0,
    },
    countdown: {
        paddingHorizontal: 30,
    },
    loading: {
        height: 92,
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontSize: 20,
        fontWeight: '400',
        marginBottom: 30,
    }]),
    countdownLabels: {
        marginTop: 10,
    },
    startBtn: {
        width: 227,
        alignSelf: 'center',
        marginVertical: 20,
    },

    separatorTopLine: {
        height: 1,
        backgroundColor: mbApplyTransparency('#FFFFFF', 0.50),
        marginHorizontal: -20,
        ...mbShadow({
            offsetHeight: -1,
            offsetWidth: 0,
            color: '#FFFFFF',
            opacity: 0.25,
            elevation: 1,
            radius: 10,
        }),
    },
    separatorBottomLine: {
        height: 1,
        backgroundColor: mbApplyTransparency('#8E9396', Platform.OS === 'ios' ? 1 : 0.50),
        marginHorizontal: -20,
        ...mbShadow({
            offsetHeight: 1,
            offsetWidth: 0,
            color: '#000000',
            opacity: 0.05,
            elevation: 1,
            radius: 10,
        }),
    },

    bottomSection: {
        marginTop: 20,
    },
    bottomSectionHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15,
    },
    bottomSectionTitleText: mbTextStyles([textStyles.normalText, {
        fontWeight: '600',
        color: '#292E30',
        textAlign: 'left',
    }]),
    bottomSectionNote: {
        flexDirection: 'row',
        marginLeft: 'auto',
        alignItems: 'center',
    },
    bottomSectionNoteText: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#292E30', 0.55),
        marginLeft: 5,
    }]),
});
